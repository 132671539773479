import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// This hook checks the subscription status of the user
export const useSubStatus = () => {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [checkingSubStatus, setCheckingSubStatus] = useState(true);
  const [subStatus , setSubStatus] = useState(null);
  const [subTier , setSubTier] = useState(null);
  
  
  const { subInfo } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (subInfo.subCount === 0) {
        setHasSubscription(false)
        
    } else {
        setHasSubscription(true)
        setSubStatus(subInfo.subStatus);
        setSubTier(subInfo.subTier);
    }
    setCheckingSubStatus(false)
  }, [subInfo])

  return { hasSubscription, checkingSubStatus, subStatus, subTier }
}
