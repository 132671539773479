// this compoenent will house the pricing table for the stripe payment system. 
//3 cards will be displayed with the following features:

//1. Basic
    //Only Attendance Tracking 
    //15 usd per month or a year for 120. To incetivice the customer to pay for a year in advance, we will offer a 20% discount.
//2. Pro    
    //Attendance Tracking.
    //Chat Between employess and super 
    //
//3. Enterprise
import React, { useState } from 'react';
//get the status of the user, if subscribed, then display the subscription details. 

//if not subscribed, the display the priciing tbale. 
import {  FaCheck } from 'react-icons/fa';
import {  useDispatch } from 'react-redux';
import { goodMo,goodYr, 
        betterMo, betterYr, 
        bestMon, bestYr } from '../../features/subscription/subscriptionSlice';

//we can get the status of the user by checking the subscription custom hook.

const PricingTable = () => {
    const dispatch = useDispatch();


    const [selectedCard, setSelectedCard] = useState(null);
    //

    const handleStartGoodMonthlySubscriptio = () => {
        // dispatch action to start the monthly good subscription.
        dispatch(goodMo());
        // will dispatch an action to the backend to start the yearly subscription.
       
    }

    const handleStartGoodYearlySubscription = () => {
       //dispatch action to start the yearly good subscription.
        dispatch(goodYr());
    }
    const handleStartBetterMonthlySubscription = () => {
        dispatch(betterMo());
    }
    const handleStartBetterYearlySubscription = () => {
        dispatch(betterYr());
    }
    const handleStartBestMonthlySubscription = () => {
       dispatch(bestMon());
    }
    const handleStartBestYearlySubscription = () => {
       dispatch(bestYr());
    }
    

    return (
                <>
                   <div className="rounded-md flex justify-around flex-col space-y-4 p-2  sm:flex-row sm:space-y-0 sm:space-x-4 md:bg-black">
                        {/* First Card */}
                        <div className=" bg-black shadow-lg flex-1 flex flex-col justify-between ">
                            <div className='mt-2 py-4 px-8'>
                                <div className="text-left text-white">
                                    <h1 className=" font-sans text-3xl mb-4">Good</h1>
                                </div>
                                <div className='text-left text-slate-300'>
                                    <p className="text-xs">Create various jobsites and track employess' hours throught each one</p>
                                </div>
                                {/* Pricing for first card */}
                                <div className="text-left mt-6">
                                    <h1 className="text-4xl font-semibold text-white">
                                        $15
                                        <span className="text-slate-300 text-sm"> per month</span>
                                    </h1>
                                    <div className="flex justify-center items-center">
                                        <h1 className='text-2xl text-white font-semibold'>Or</h1>
                                    </div>
                                    <div>
                                        <h1 className="text-4xl font-semibold text-yellow-400">
                                            $120
                                            <span className="text-yellow-400 text-sm"> per year</span>
                                        </h1>
                                        <p className="text-xs text-yellow-500 text-center underline font-semibold">
                                            20% off
                                        </p>
                                    </div>
                                </div>
                                   
                            {/* conditionaly render buttons */}
                            {/* if card selected is not good show this */}
                                {selectedCard !== 'good' && (
                                    <button
                                        type="button"
                                        className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                        onClick={() => setSelectedCard('good')} 
                                    >
                                        7 day free trial
                                    </button>
                                )}
                                {/* else we will show these two cards */}
                                {selectedCard === 'good' && (
                                    <>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartGoodMonthlySubscriptio}
                                        >
                                            Monthly
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartGoodYearlySubscription}
                                        >
                                            Yearly
                                        </button>
                                    </>
                                )}
                        

                                <div className="text-slate-300 text-sm mt-2 mb-4 text-left">
                                    <h1 className='text-md mb-0'> This includes:</h1>
                                    <ul className="mt-1">
                                        <li className="flex items-center"><FaCheck /><span className="ml-1">View Attendance</span></li>
                                        <li className="flex items-center"><FaCheck /><span className="ml-1">Field Devices</span></li>
                                        <li className="flex items-center"><FaCheck /><span className="ml-1">Unlimited Jobsites</span></li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Second Card */}
                        <div className="rounded bg-black shadow-lg flex-1 flex flex-col justify-between">
                            <div className='mt-2 py-4 px-8'>
                                <div className="text-left text-white">
                                    <h1 className=" font-sans text-3xl mb-4">Better</h1>
                                </div>
                                <div className='text-left text-slate-300'>
                                    <p className="text-xs">Designate workers as supervisors to manage specific jobsites and other workers</p>
                                </div>
                                {/* Pricing for second card */}
                                <div className="text-left">
                                    <div className='mt-8'>
                                        <h1 className="text-4xl font-semibold text-white">
                                            $22
                                            <span className="text-slate-300 text-sm"> per month</span>
                                        </h1>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <h1 className='text-2xl text-white font-semibold'>Or</h1>
                                    </div>
                                    <div className="mt-1">
                                        <div className="text-4xl font-semibold text-yellow-500 mb-0">
                                            $212
                                            <span className="text-yellow-400 text-sm mb-0"> per year</span>
                                        </div>
                                        <p className="text-xs mt-0 text-yellow-500 text-center underline font-semibold">
                                            20% off
                                        </p>
                                    </div>
                                </div>
                                 {/* conditionaly render buttons */}
                            {/* if card selected is not good show this */}
                            {selectedCard !== 'better' && (
                                    <button
                                        type="button"
                                        className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                        onClick={() => setSelectedCard('better')} 
                                    >
                                        7 day free trial
                                    </button>
                                )}
                                {/* else we will show these two cards */}
                                {selectedCard === 'better' && (
                                    <>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartBetterMonthlySubscription}
                                        >
                                            Monthly
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartBetterYearlySubscription}
                                        >
                                            Yearly
                                        </button>
                                    </>
                                )}
                        

                                <div className="text-slate-300 text-sm mt-2 mb-4 text-left">
                                    <h1 className='text-md mb-0'> Includes Good plus:</h1>
                                    <ul className='mt-1'>
                                        <li className="flex items-center "><FaCheck /><span className="ml-2">Unlimited Jobsites</span></li>
                                        <li className="flex items-center "><FaCheck /><span className="ml-2">Message Board</span></li>
                                        <li className="flex items-center"><FaCheck /><span className="ml-1">Unlimited Workers</span></li>
                                      
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Third Card */}
                        <div className="rounded bg-black shadow-lg flex-1 flex flex-col justify-between">
                            <div className='mt-2 py-4 px-8'>
                                <div className="text-left text-white">
                                    <h1 className=" font-sans text-3xl mb-4">Best</h1>
                                </div>
                                <div className='text-left text-slate-300'>
                                    <p className="text-xs">Everything in other plans plus, analytics, and daily logs</p>
                                </div>
                                <div className="text-left mt-6">
                                    <div>
                                        <h1 className="text-4xl font-semibold text-white">
                                            $30
                                            <span className="text-slate-300 text-sm"> per month</span>
                                        </h1>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <h1 className='text-2xl text-white font-semibold'>Or</h1>
                                    </div>
                                    <div className="mt-1">
                                        <div className="text-4xl font-semibold text-yellow-500 mb-0">
                                            $365
                                            <span className="text-yellow-400 text-sm mb-0"> per year</span>
                                        </div>
                                        <p className="text-xs mt-0 text-yellow-500 text-center underline font-semibold">
                                            20% off
                                        </p>
                                    </div>
                                </div>
                              {/* subscribe buttons for best */}
                               {/* conditionaly render buttons */}
                            {/* if card selected is not good show this */}
                            {selectedCard !== 'best' && (
                                    <button
                                        type="button"
                                        className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                        onClick={() => setSelectedCard('best')} 
                                    >
                                        7 day free trial
                                    </button>
                                )}
                                {/* else we will show these two cards */}
                                {selectedCard === 'best' && (
                                    <>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartBestMonthlySubscription}
                                        >
                                            Monthly
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-green-400 font-bold mt-4 py-2 px-4 rounded-lg w-full"
                                            onClick={handleStartBestYearlySubscription}
                                        >
                                            Yearly
                                        </button>
                                    </>
                                )}
                        
                                <div className="text-slate-300 text-sm mt-2 mb-4 text-left">
                                    <h1 className='text-md mb-0'>Includes better plus:</h1>
                                    <ul className='mt-1'>
                                       
                                        <li className="flex items-center "><FaCheck /><span className="ml-2">Analytics</span></li>
                                        <li className="flex items-center "><FaCheck /><span className="ml-2">Daily work logs</span></li>
                                        <li className="flex items-center"><FaCheck /><span className="ml-2">Workers database access</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                
                </>
            );
        };

export default PricingTable;
