import OldWay from "../components/homeComponents/OldWay"
import Intro from "../components/homeComponents/Intro"
import TypicalProblems from "../components/homeComponents/TypicalProblems"
import Faq from "../components/homeComponents/Faq"
import Pricing from "../components/homeComponents/Pricing"
const Home = ()=>{
    return (
        <>    
            <Intro/>
            <OldWay/>
            <TypicalProblems/>
            <Pricing/>
            <Faq/>
        </>
    )
}

export default Home