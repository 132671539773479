import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import  BackButton from '../components/BackButton'
import {toast} from 'react-toastify'
import {createWorker, reset} from '../features/workers/workerSlice'
import Spinner from '../components/Spinner'
import { useEffect } from 'react'

function NewWorker(){
    
   
    const {isLoading, isError, isSuccess, message} = useSelector(
        (state) => state.workers     //-> why workers work and not worker 
    )

    const dispatch = useDispatch();
    const navigate =useNavigate();

    //get user ID from redux we wont change this. 
    
    const [workerLname, setLastName] = useState('')
    const [workerName, setName] = useState('')
    const [workerPhone, setPhone] = useState('')
    const [workerRole, setWorkerRole] = useState('')
    const [workerRate, setRate] = useState('')

   
    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess){
            navigate('/workers')
        }

        dispatch(reset)
    },[dispatch,isError,isSuccess,navigate,message])

    const onSubmit =(e) =>{

        e.preventDefault()

        dispatch(createWorker({  workerName, workerLname, workerPhone, workerRole , workerRate }))
        
    }

    if(isLoading){
        return <Spinner />
    }

   return (

    <>
        <div className='text-left'>
            <BackButton url='/homedash' />

        </div>
       
       
       <div>
        <h2 className='text-4xl font-bold'> New Worker</h2>
       </div>


       <div className='form-group mx-auto max-w-2xl bg-slate-50 p-4 rounded-lg'>
            <form onSubmit={onSubmit}>
            

            <label htmlFor='name'>Name</label>
            <input
                required
                type='text'
                name='name'
                id='name'
                className='form-control'
                placeholder="John"
                value={workerName}
                onChange={(e) => setName(e.target.value)}
            ></input>

            <label htmlFor='workerLname'>Last Name</label>
            <input
                required
                type='text'
                name='workerLname'
                id='workerLname'
                className='form-control'
                placeholder="Doe"
                value={workerLname}
                onChange={(e) => setLastName(e.target.value)}
            ></input>

            <label htmlFor='workerLname'>Phone Number</label>
            <input
                type='cell'
                name='phone'
                pattern="[1-9]{1}[0-9]{9}"
                maxLength="10"
                id='phone'
                className='form-control'
                placeholder="123-456-1234"
                value={workerPhone}
                onChange={(e) => setPhone(e.target.value)}
            ></input>

         

            <label htmlFor='workerRole'>Role</label>
            <input
                required
                type='text'
                name='workerRole'
                id='workerRole'
                className='form-control'
                placeholder="Metal Framer"
                value={workerRole}
                onChange={(e) => setWorkerRole(e.target.value)}
            ></input>

            <label htmlFor='workerRate'>Hourly Rate</label>
            <input
                required
                type='number'
                name='workerRate'
                id='workerRate'
                className='form-control'
                placeholder="25.75"
                value={workerRate}
                onChange={(e) => setRate(e.target.value)}
            

            ></input>   

            <div className='form-group'>
            <button className='bg-black text-white p-2  rounded-lg'> Submit</button>
        </div>

            
               
            </form>
             
        </div>
    </>

   )

}

export default NewWorker