// The NAVBAR for the application.
import {FaHome, FaSignInAlt, FaSignOutAlt, FaClock, FaBars} from 'react-icons/fa'
import {Link, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux'
import {logout, reset} from '../features/auth/authSlice'
import {logoutWorker, resetWorkerUser} from '../features/workerAuth/workerAuthSlice'
import { subscriptionSlice} from '../features/subscription/subscriptionSlice'
import React, { useState } from 'react';
function Header (){

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    

    // Hooks to get the user and workerUser from the redux store.
    const {user} = useSelector((state)=> state.auth)
    const {workerUser} = useSelector((state)=> state.workerAuth)



    // logout user
    const onLogout = () =>{
        dispatch(subscriptionSlice.actions.reset()); //reset the subscription state
        dispatch(logout())
        dispatch(reset())
        navigate("/login")
    }

    //logout worker
    const onWorkerLogout =()=>{
        
        dispatch(logoutWorker())     //removes the workerUser data from local storage
        dispatch(resetWorkerUser())  //resents the redux state to initial state for slice workerAuth
        navigate("/workerLogin")
    }

    return(
       
<header className="flex items-center justify-between p-2 bg-black mb-2">
    {/* Logo - Visible on all screens */}
    <div className="flex-shrink-0">
        <Link className='font-mono font-bold uppercase  md:text-2xl text-red-600' to='/'>TimeStat</Link>
    </div>

    {/* NavBar Buttons */}
    <div className=''>
        <ul className='flex space-x-4'>
            {/* Conditional rendering based on user or workerUser login status */}
            {!user && !workerUser && (
                <>
                <ul className='flex'>
                        <li className='flex'>
                            <Link className="hover:bg-red-600 px-2  rounded-md text-slate-400" to="/login" aria-label="Business Login">
                                <FaSignInAlt className="inline-block mr-1" /> Business
                            </Link>
                        </li>
                        <li className='flex'>
                            <Link className=" hover:bg-red-600 px-2 rounded-md text-slate-400" to="/workerLogin" aria-label="Worker Login">
                                <FaClock  className="inline-block mr-1"/> Worker
                            </Link>
                        </li>

                </ul>
                   
                </>
            )}

            {workerUser && (
                <li>
                    <button 
                        className="text-white bg-red-500 hover:bg-red-600 px-3 py-2 rounded" 
                        onClick={onWorkerLogout} aria-label="Log Out Worker">
                        LogOut Worker
                    </button>
                </li>
            )}

            {user && (
                <>
                    <li className='flex'>
                        <Link 
                            className="text-slate-400 hover:bg-red-600 px-2 rounded " 
                            to="/homedash" 
                            aria-label="Home Dashboard">
                            <FaHome className='inline-block mr-1'/>
                             {user.company}
                        </Link>
                    </li>
                    <li className='flex'>
                        <button 
                            className="text-slate-400  hover:bg-red-600 px-2  rounded" 
                            onClick={onLogout} aria-label="Log Out">
                            <FaSignOutAlt className="inline-block mr-1"/> LogOut
                        </button>
                    </li>
                </>
            )}
        </ul>
    </div>
</header>
    )
}

export default Header