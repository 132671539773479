
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import NonRegisteredItem from '../components/NonRegisteredItem'
import {useSelector} from 'react-redux'


function RegistryAddWorker(){

    const {siteInfo}= useSelector((state)=> state.sites) 
    
    const {nonRegistered, isLoading}= useSelector((state)=> state.registry) 
    const back = `/sites/${siteInfo.id}/registry`
    

    if(isLoading){
        return<Spinner />
    }
    return (
        <>
        <BackButton  url={back} />
        <section>
            <h1>  Workers not registered in {siteInfo.siteName}     </h1>
        </section>

        <div className="ticket-headingsR">
             <div>
                Name
            </div>
            <div>
             Last Name
            </div>
            <div>
             Phone 
            </div>
            <div>
            Role
            </div>
            <div>
            Register in {siteInfo.siteName}
            </div>


 
                
        </div>
         {nonRegistered.map((worker) =>(
             <NonRegisteredItem key={worker.workerID} worker={worker} />
                      
             )
             )}
   
        </>
    )
}

export default RegistryAddWorker
