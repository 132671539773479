import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';


const SetupForm = () => {
  const { token } = useSelector((state) => state.auth.user);

  //url for stripe checkout session
  // it provides for a secure stripe hosted page for the user to enter their card details. 
  const [checkoutUrl, setCheckoutUrl] = useState('');

  const handleAddCard = async (event) => {
    event.preventDefault();

    try {
      
      const res = await axios.get('/api/pay/cardSetUp', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCheckoutUrl(res.data);
    } catch (err) {
      console.log(err);
    }
  };


  //when the checkout url changes, we redirect the user to the stripe hosted page. 
  useEffect(() => {
    if (checkoutUrl !== '') {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  return (
    <>
    <div className='mx-auto px-4 max-w-3xl'>
         
     
      <button type='button' className='bg-green-500 rounded-md px-2 py-1 text-md '
        onClick={handleAddCard}>
        Add a new card
      </button>
      
    </div>
    
    </>
 
  );
};

export default SetupForm;
