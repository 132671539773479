
import { Link } from 'react-router-dom'

import { IoChevronBack } from "react-icons/io5";
const BackButton = ({url}) => {
    return(
        
        <Link to={url} className="bg-slate-600 text-white inline-flex items-center p-2 rounded-lg hover:bg-black">
            <IoChevronBack className=' text-2xl'/> Back
        </Link>
    )
}

export default BackButton