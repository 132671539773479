//will send http request to backend to submit json note 

import axios from "axios"



export const submitNote = async (token, jobsiteID, messageData) => {
  
    try {
        const response = await axios.post(`/api/dash/jobsite-note/${jobsiteID}`, messageData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });
        // Handle the response here
        if(response.status === 200){
            console.log(response.data)
            return 
        }
        
    } catch (error) {
        // Handle the error here
        console.error(error);
    }
}
