//This file will check if a workerUser is logged in and grant access to protected routes.

import { Navigate, Outlet } from "react-router-dom"
import { useAuthStatus } from "../hooks/useAuthStatus"
import Spinner from "./Spinner"

const PrivateWorkerRoute = () =>{

    const {workerLoggedIn, checkingStatus} = useAuthStatus()

    if(checkingStatus){
        return <Spinner />
    }
    return workerLoggedIn ? <Outlet /> : <Navigate to='/workerLogin' />;


   
}


export default PrivateWorkerRoute

