//This page will implement the facial livenes UI from aws amplify 

import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { ThemeProvider, Loader } from '@aws-amplify/ui-react';
import {FaceLivenessDetector} from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';  
import awsexports from '../aws-exports'


//aws exports is in the gitignore file
Amplify.configure(awsexports);

//onLivenessResult is a function that will be passed from the parent component.
//it takes the result of the liveness test and the session id as arguments.
function FacialLiveness ({onLivenessResult}){    

    const [isLive, setIsLive] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {
    
        //call backend to create a liveness session, assign the session id to the state sessionId
        const fetchCreateLiveness = async () => {
         
            const response = await fetch(process.env.REACT_APP_LIVENESS_CreateSession).catch((error) => {
                alert('Error: ' + error);   
                console.error('Error:', error);
            });

            const data = await response.json(); 
            
            console.log(data)
            
            setSessionId(data.livenessSessionId);
            
            setLoading(false);
        }
    
        // I think i can remove this 
         fetchCreateLiveness();
    
    
      }, []);


  //get the analysis data from the backend.
  const handleAnalysisComplete = async () => {
    //console.log('Analysis complete for session: ', sessionId   );

     /*
     * This should be replaced with a real call to your own backend API
     */

    const response = await fetch(process.env.REACT_APP_LIVENESS_GET_SESSION_RESULTS_API,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',  
            'Content-Type': 'application/json'
      },
        body: JSON.stringify({sessionId: sessionId})
    });
    /*
     * Note: The isLive flag is not returned from the GetFaceLivenessSession API
     * This should be returned from your backend based on the score that you
     * get in response. Based on the return value of your API you can determine what to render next.
     * Any next steps from an authorization perspective should happen in your backend and you should not rely
     * on this value for any auth related decisions.
     */
    const data = await response.json();

    //data.isLive  holds true or false
    if (data.isLive === true) {
        setIsLive(true);
        //pass to the parent component the result of the liveness test along with the session id
        onLivenessResult(true, sessionId);

        // window.ReactNativeWebView.postMessage("verification Passed"); //communicates to the webview to close the webview in react native
      } else {
        // window.ReactNativeWebView.postMessage("verification Failed");
        setIsLive(false);
      }
  }
  return (
    <>
        
        <ThemeProvider>
            {/* while getting the session id, render the loading bar */}
            {loading ? (
                <Loader />
            ) : (
            <>

                {isLive === null 
                ? (
                    <>
                    <p>Bring phone near your face to start.</p>
                    <FaceLivenessDetector
                        sessionId={sessionId}
                        region="us-east-1"
                        onAnalysisComplete={handleAnalysisComplete}
                        onError={(error) => {
                        console.error(error.stack);
                        }}
                        disableStartScreen={true}
                    />
                    </>
                ) 
                : isLive 
                    ? <p>Thank You</p> 
                    : <p>Fake Person</p>
                }
            
            </>
            
                
        
            )}
        </ThemeProvider>
    </>
  );
}

export default FacialLiveness