// {} is an object
function SiteItem({transaction}){

  // Utility function to convert a UTC time string to Eastern Standard Time
      function dateFormat (databaseDate) {
            // In UTC String ISO format
            let date = new Date(databaseDate);
            let localTime = date.toLocaleTimeString();
            return localTime;
            
      }
      function timeDifference(timeIn, timeOut) {
        let t1 = new Date(timeIn);
        let t2 = new Date(timeOut);

        let differenceInMilliSeconds = t2 - t1;
        let differenceInHours =  differenceInMilliSeconds / (1000 * 60 * 60);
        return Math.round(differenceInHours * 100) / 100 ;

      }
      
      return(
          //change class name in CSS file
          <div className='ticket'>
              <div>
                  <ul>
                    <li>{transaction.worker.name} </li> 
                    <li> {transaction.worker.lastName}</li>
                    <li>{timeDifference(transaction.timeIN,transaction.timeOut)} HR</li>
                  
                  </ul>
                 
                  
              </div>
              <div>{transaction.worker.role}</div>
              <div>{dateFormat(transaction.timeIN) }</div>
              <div>{dateFormat(transaction.timeOut)}</div>
                       
              
             
            
  
          </div>
  
      )
  }
  
  export default SiteItem