// This is the Assign Code Button used in the registry Page. 

import { FaPlusSquare} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNonRegistered } from '../features/registry/registrySlice'
import { useState } from 'react'
import {submitDeviceCode} from '../features/registry/registrySlice'


const AddButton = ({url}) => {
    const{siteInfo} = useSelector((state => state.sites))
    
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const dispatch = useDispatch()

    
    const handleInputChange = (e) => {
        const input = e.target.value
        let formattedInput = input.replace(/[^A-Za-z0-9]/g, '').substring(0, 23)
        formattedInput = formattedInput.split('').reduce((result, char, index) => {
            return result + char + ((index % 2 !== 0 && index !== formattedInput.length - 1) ? ',' : '')
        }, '')
        setInputValue(formattedInput)
    }

    const handleAssignCode = () => {
        const data = {
            deviceCode: inputValue,
            siteID: siteInfo.id,
            siteName : siteInfo.siteName, 
        }

        dispatch(submitDeviceCode(data))
        handleModalClose()
        
    }

    const handleModalOpen = () => {
        setModalIsOpen(true)
    }

    const handleModalClose = () => {
        setModalIsOpen(false)
        setInputValue('')
    }

    return(
        <>
            <div className="flex justify-center items-center gap-4">
                <Link
                    to={url}
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded inline-flex items-center gap-2"
                    onClick={() => {
                        dispatch(getNonRegistered())
                    }}
                >
                    <FaPlusSquare/> Register worker to device
                </Link>

                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center gap-2"
                    onClick={handleModalOpen}
                > 
                    Assign code
                </button> 
            </div>

            {modalIsOpen && (
                

         
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white rounded-lg p-4">
                            <h2 className="text-xl font-bold mb-4">Enter code</h2>
                            <p>Option C on your device will reveal code </p>
                            <input
                                type="text"
                                maxLength={23}
                                value={inputValue}
                                onChange={handleInputChange}
                                className="border border-gray-300 rounded-lg p-2 mb-4"
                            />
                            <div className="flex justify-end">
                                <button
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                    disabled={inputValue.length !== 23}
                                    onClick={handleAssignCode}
                                
                                    
                                >
                                    Assign
                                </button>
                            </div>
                        </div>
   
                </div>
            )}
        </>
    )
}

export default AddButton
