import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
const UpdateAddressModal = ({onClose}) =>{

    //redux token
    const { token } = useSelector((state) => state.auth.user);
    
    const [formData, setFormData] = useState({
        type: '',
        lineOne:'',
        lineTwo:'',
        city:'',
        state:'',
        zipCode:'',
       
    }) 

    const { type,lineOne, lineTwo, city, state, zipCode  } = formData

    
    
    
    //Send foromData to backend API.    Use redux dispatch. 
    const onSubmit = async (event) => {
        
        event.preventDefault();
        console.log(formData)
        try {
            const response = await axios.post("/api/users/postAddress", formData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              console.log(response.data);
              onClose();
            

        } catch (error) {
          console.log(error);
        }
      };
    
      const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      




    return (
    <>
      
        <div className="mt-2 rounded bg-gradient-to-b from-slate-300 to-slate-100 ">
          <h1 className="text-lg underline  mb-2">Update Address</h1>
          <form onSubmit={onSubmit} className="rounded p-4">
            <div className="flex flex-col space-y-4">

            <div className='flex justify-center'>
              <label className="font-sm mb-2">Type:
                  <select name="type" value={type} onChange={onChange} className="rounded ml-2" required>
                      <option value=""></option>
                      <option value="Shipping">Shipping</option>
                      <option value="Billing">Billing</option>
                      <option value="Both">Both</option>
                  </select>
              </label>

            </div>
            
              {/* Typed inputs */}
           
              <label className="font-sm mb-2">Line One:
                <input  name='lineOne' type="text" id='lineOne' placeholder="123 Main ST" value={lineOne} onChange={onChange} className="roundedborder p-1 ml-2" required />
              </label>
              <label className="font-sm mb-2">Line Two:
                <input name='lineTwo' type="text" id='lineTwo' placeholder="Suite 1A " value={lineTwo} onChange={onChange} className="rounded border p-1 ml-2" />
              </label>
              <label className="font-sm mb-2">City:
                <input  name='city' type="text" id='city' placeholder="New York"  value={city} onChange={onChange} className="rounded border p-1 ml-2" required />
              </label>
              <label className="font-sm mb-2">State:
                <input name='state' type="text" id='state' placeholder="NY" value={state} onChange={onChange} className="rounded  border p-1 ml-2" required />
              </label>
              <label className="font-sm mb-2">ZipCode:
                <input name='zipCode' type="text" id='zipCode' placeholder="10021" value={zipCode} onChange={onChange} className="rounded  border p-1 ml-2" required />
              </label>
            </div>

            {/* Buttons */}
            <div className="flex justify-center space-x-4 mt-4">
              <button  type='button' className="bg-slate-300  p-1 rounded hover:bg-gray-700 hover:text-white" onClick={onClose}>Cancel</button>
              <button type="submit" className="bg-sky-200 p-1  rounded hover:bg-blue-400 hover:text-white">Submit</button>
            </div>
          </form>
        </div>

    </>
    
    )
}

export default UpdateAddressModal