import { addToRegistry , getNonRegistered, reset} from "../features/registry/registrySlice"
import {useSelector, useDispatch} from 'react-redux'

function NonRegisteredItem({worker}){

    const {siteInfo}= useSelector((state)=> state.sites) 

   let data = {
    devId: siteInfo.id,
    workerId : worker.id,
    
   }

   const dispatch = useDispatch();

    return(
        //change class name in CSS file
       <div className="ticket-headingsR">
             <div> 
                {worker.name} 
             </div>
           
           <div>    
                    {worker.lastName}       
           </div>

           <div>
            {worker.phone}
           </div>
           <div>
            {worker.role}
           </div>
           <div>
           <button className={`status btn status-open }`} 
            onClick={() => {
                
             dispatch(addToRegistry(data))
             dispatch(reset())
             dispatch(getNonRegistered())

           }} >
                
                   Click To register
              

            </button>

           </div>
       </div>
       

    )
}

export default NonRegisteredItem