

// Shows ther user's information, including their cards, contact information, and subscription details.

import BackButton from '../components/BackButton';
import SetupForm from '../components/stripe/SetupForm';
import CardsList  from '../components/stripe/CardsList';
import SubscriptionDetails from '../components/SubscriptionDetails';
import ContactInfo from '../components/ContactInfo'
const UserInfo = () => {
 
  return (
    <>
    
   
    <div className='sm:w-full md:max-w-3xl  '>
            <div className='mt-2 bg-slate-00 p-2 rounded'>
                {/* List cards belonging to the user */}
                <CardsList />
               
                {/* Add New Card */}
                <SetupForm />  
            </div>
            
           
            <div className='mt-4'>
                <ContactInfo/>
            </div>

            {/* Subscription information of the user's account */}
            <div className='mt-4'>
              <SubscriptionDetails/>
            </div>
           
            
    </div>
    
    </>
 
  );
};

export default UserInfo;
