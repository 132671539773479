import registryService from './registryService' 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
    registryInfo: [],
    nonRegistered : [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',

}


//Get Registry information for a particular Site
export const getRegistry = createAsyncThunk('registry/getRecords', 
    async( siteId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await registryService.getRegistry( siteId,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

export const toggleRegistryStatus = createAsyncThunk('registry/toggleStatus', 
    async( toggleData, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await registryService.switchRegistryLockStatus(toggleData,token)  //toggle data gets passed by the caller function. 
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})



export const getNonRegistered = createAsyncThunk('registry/getNonRegistered', 
    
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            const siteId = thunkAPI.getState().sites.siteInfo.id
            return await registryService.getNonRegistered( siteId,token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

export const addToRegistry = createAsyncThunk ('registry/addWorker', async(data, thunkAPI) =>{
    try {
        const token = thunkAPI.getState().auth.user.token
        return await registryService.assignWorkerToRegistry( data , token)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
    
    return thunkAPI.rejectWithValue(message)
    }
})

export const submitDeviceCode = createAsyncThunk ('registry/devCode' , async (data,thunkAPI) =>{
    try {

        //  console.log(data.deviceCode)
        //  console.log(data.siteID)
        //  console.log(data.siteName)
          const token = thunkAPI.getState().auth.user.token
         return await registryService.assignDevCode( data , token)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
    
    return thunkAPI.rejectWithValue(message)
    }
})



export const registrySlice = createSlice ({
    name: 'registry',
    initialState,
    reducers:{

        reset: (state) => initialState,
        
    },
    extraReducers : (builder) => {
        builder 
        .addCase(getRegistry.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getRegistry.fulfilled, (state, action) => {
            state.isSuccess = true
            state.isLoading = false
            state.registryInfo = action.payload
        })
        .addCase(getRegistry.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(toggleRegistryStatus.pending, (state) => {
            state.isLoading = true
        })
        .addCase(toggleRegistryStatus.fulfilled, (state, action) => {
            state.isSuccess = true
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(toggleRegistryStatus.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(getNonRegistered.pending, (state) => {
            state.isLoading = true
           
        })
        .addCase(getNonRegistered.fulfilled, (state, action) => {
            state.isSuccess = true
            state.isLoading = false
            state.nonRegistered = action.payload
        })
       
        .addCase(getNonRegistered.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(addToRegistry.pending, (state) => {
            state.isLoading = true
           
        })
        .addCase(addToRegistry.fulfilled, (state, action) => {
            state.isSuccess = true
            state.isLoading = false
            state.message = action.payload
           
        })
       
        .addCase(addToRegistry.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(submitDeviceCode.pending, (state) => {
            state.isLoading = true
           
        })
        .addCase(submitDeviceCode.fulfilled, (state, action) => {
            state.isSuccess = true
            state.isLoading = false
            state.message = action.payload
           
        })
       
        .addCase(submitDeviceCode.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.message = action.payload
        })

       
        

    }
})

export const{reset} = registrySlice.actions
export default registrySlice.reducer