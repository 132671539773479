//when edit button is pressed in the parent, this component should a render within the card component, within the notes section.


//as props accept the jobsite id, and the notes object.
import React, { useState, useEffect } from 'react';
import { TbPlus } from "react-icons/tb";
import { FaCheckDouble } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { submitNote } from '../actions/submitNote';

const NotesInCards = ({ jobSite , setEditNotesClicked}) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    //grab the user token from redux store
    const {token} = useSelector(state => state.auth.user);
    //this is a json object of notes. It may be empty, or it may have notes in it. It will be sent to the backend to stire in the database. 
    const [notes, setNotes] = useState({});
    //sets the edit notes button to false, which will close the edit notes component.
    const handleCancel = () => {
       
         setNotes({}); //clears the notes object

         //get notes from the backend and set them to the notes object, pass them to the parent component

        setEditNotesClicked(false);
      };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    //take the existing notes object and submit it to the backend. To be stored in the database. 
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        //if subject and message are not empty, add them to the notes object
        if(subject && message  ){
           handleAddNote();
           setIsSubmitting(true);        
        

        }else{
           setIsSubmitting(true); 
        }
       
    };

    useEffect(() => {
        if (isSubmitting && Object.keys(notes).length > 0) { // only call submitNote if isSubmitting is true and notes is not empty
            submitNote(token, jobSite.id, notes)
                .then(() => {
                    toast.success('Notes submitted successfully');
                    setIsSubmitting(false); // Set isSubmitting to false after submitNote has been called
                })
                .catch((error) => {
                    // Handle error here
                    console.error(error);
                    setIsSubmitting(false); // Set isSubmitting to false if an error occurs
                });
        }
    }, [notes, jobSite.id, token, submitNote, isSubmitting]);
   

    const handleAddNote = () => {
        if (subject && message) {
            const newNote = {
                ...notes,
                [subject]: message
            };

            setNotes(newNote);

            // Clear the subject and message fields for the next note
            setSubject('');
            setMessage('');
        } else {
            toast.error('Subject and message cannot be empty');
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <label htmlFor="subject" className="block text-gray-700 font-light mb-1 text-left text-sm">
                        Subject
                    </label>
                    <input
                        type="text"
                        id="subject"
                        className="w-full  py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
                        value={subject}
                      
                        onChange={handleSubjectChange}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="message" className="block text-gray-700 font-light mb-1 text-left text-sm">
                        Message
                    </label>
                    <textarea
                        id="message"
                        className="w-full py-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
                        value={message}
                        onChange={handleMessageChange}
                    ></textarea>
                    <p className='text-left text-sm font-mono text-indigo-800'>Messages count: {Object.keys(notes).length || 0}</p>
                </div>

                {/* Notes action buttons */}
                <div className="flex justify-center space-x-4 text-slate-400 ">
                   
                    <button className='relative group' onClick={handleCancel} >
                       <MdOutlineCancel className='text-xl cursor-pointer   hover:text-slate-500'></MdOutlineCancel>
                       <span className="absolute top-[-30px] p-0.5   flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black
                             text-white text-xs rounded-md pointer-events-none font-thin">
                             Close
                        </span>
                    </button>
                   
                    <button className='relative group' type='button' onClick={handleAddNote}> 
                        <TbPlus className="text-xl cursor-pointer  hover:text-slate-500" />
                        <span className="absolute top-[-40px] l-[20px] p-0.5   flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black
                             text-white text-xs rounded-md pointer-events-none font-thin">
                             Add Note
                        </span>
                    </button>

                   
                        {/* button to submit the Json of notes */}
                    <button className="relative group" type="submit" >
                        <FaCheckDouble className='text-lg cursor-pointer  hover:text-slate-500'> </FaCheckDouble>
                        <span className="absolute top-[-40px] p-0.5   flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black text-white text-xs rounded-md pointer-events-none font-thin">
                             Submit Notes
                        </span>
                    </button>
                </div>
            </form>
        </>
    );
};

export default NotesInCards;

