import axios from "axios";

const API_GETREGISTRY = "/api/dash/registry/"


//get Single Worker Info 
const getRegistry = async(siteId, token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETREGISTRY + siteId, config)
    return response.data

}  


const switchRegistryLockStatus = async(toggleData, token) =>{
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }
    const response = await axios.post(`/api/dash/registry/${toggleData.devId}/toggle`, toggleData , config)  //pass toggle data second or third ? see post in siteService
    return response.data
}


//get non registered workers
const getNonRegistered = async(siteId,token)=>{
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    
    const response = await axios.get(`/api/dash/registry/${siteId}/notRegistered` , config)
    return response.data
}


const assignWorkerToRegistry = async(data, token) =>{
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }
    
    const response = await axios.post(`/api/dash/registry/${data.devId}/add`, data , config)
    return response.data
}

//assign device code 
const assignDevCode = async(data, token) =>{
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }
    
    const response = await axios.post(`/api/dash/updateSiteCode`, data , config)
    return response.data
}



const registryService = {
    getRegistry,
    switchRegistryLockStatus,
    getNonRegistered,
    assignWorkerToRegistry,
    assignDevCode,
}
export default registryService