//Display workers on given stie
import { useState } from 'react'
import DatePicker from 'react-date-picker';
import DateFilterButton from '../components/DateFilterButton'
import BackButton from '../components/BackButton';
//rename to SiteTransactions
import {useDispatch, useSelector} from 'react-redux' 
import {toast} from 'react-toastify'
import { reset} from '../features/transactions/transactionsSlice'
import Spinner from '../components/Spinner';
import TransactionItemBySite from '../components/TransactionItemBySite'

// Rename this file as transactionsBySiteId
function Site(){

    const {transactions, isLoading, isError, message} = useSelector((state => state.transactions))
    const {siteInfo} = useSelector((state) => state.sites)
    
    const [dateValue, onChange] = useState(new Date())

    
    //pass value to a dispatch function, to refresh the page and and resolad new results 

    const dispatch = useDispatch()

    if(isError){
        dispatch(reset())
        toast.error(message)

    }
    if(isLoading){
        return <Spinner/>
    }

    return (
        <>
        
        <div className='mb-3'>
            <p className='text-lg'>Pick a date to see who worked at <span className="text-red-500 font-bold uppercase">{siteInfo.siteName}</span></p>

            
            
            <div className='flex justify-center items-center'>
                {/* on Change a date value is assigned. */}
                <DatePicker onChange={onChange} value={dateValue} />

                {/* On Click, dateObj is sent to the backend */}
                <DateFilterButton  dateObj={dateValue} />


            </div>
          
        </div>
        
       
         <div>
                <div className='tickets'>
                    <div className='ticket-headings'>

                        <div>Worker</div>
                        <div>Role </div>
                        <div>TimeIn</div>
                        <div>TimeOut</div>    
                        
                    </div>

                </div>
        </div>

        <div class='overflow-y-auto h-96'>
                    
                   {transactions.map((transaction) =>(
                    <TransactionItemBySite key={transaction.timeIN} transaction={transaction} />
                   ))}

                  

        </div>
        
        <div className='section relative h-32 ' style={{ display: 'grid', placeItems: 'center' }}>
            {/* Place two buttons side by side. */}
            <div> 
            <BackButton url='/sites'/>
            </div>
            

            

       </div>
       
        </>
    )
}

export default Site