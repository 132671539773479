// will contain actions which will update the database
// this could be a jsx file but I think it's better to keep it as javascript file since it will not render anything.

import axios from 'axios';  


const updateImageURL_API = '/api/dash/updateImageURL';

export const updateImageURL = async (s3ObjectUrl, jobSiteID, token ) => {
    console.log("updateImageURL function called")

    const putData = {
        objectURL : s3ObjectUrl,
        jobsiteID : jobSiteID 
    }

    try {
        
        const response = await axios.put(updateImageURL_API, putData,{
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        
       
        return response.data;
        

    } catch (error) {
        console.log("Something went wrong with updating the database with the image URL.")
        throw error;
    }



}
