import axios from 'axios'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UpdateAddressModal from './UpdateAddressModal';
import {FaEdit} from 'react-icons/fa'


const Address = () => {
  const { token } = useSelector((state) => state.auth.user);
  const [address, setAddress] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getAddress = async () => {
      try {
        const response = await axios.get('/api/users/getAddress', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      
        setAddress(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    getAddress();
  }, [token,showModal]);

  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <>
    <div className="text-white mx-auto px-4 max-w-3xl">
      {address.type && (
        <ul className="list-none mb-4">
          <li className="text-lg text-bold">{address.type} Address</li>
          <li className="text-lg">{address.lineOne}</li>
          <li className="text-lg">{address.lineTwo}</li>
          <li className="text-lg">{address.city}, {address.state} {address.zipCode}</li>
        </ul>
      )}
      {address.length > 0 ? (
        address.map((addressItem, index) => (
          <ul key={index} className="list-none">
            <li className="text-md font-bold underline">  {addressItem.type === "Both" ? "Shipping & Billing" : `${addressItem.type} Address`} </li>
            <li className="text-md">{addressItem.lineOne}</li>
            <li className="text-md">{addressItem.lineTwo}</li>
            <li className="text-md">{addressItem.city}, {addressItem.state} {addressItem.zipCode}</li>
          </ul>
        ))
      ) : (
        <p className="text-sm mt-3 text-red-500">Business address missing</p>
      )}
      <button 
        className="bg-black hover:bg-gray-600 hover:text-white rounded mt-0 p-1 text-2xl font-medium"
        onClick={handleUpdateClick}
        >
        
        {address.length < 1 ? "Add Address" : <FaEdit />}
       
      </button>
      
    </div>

    {showModal && (
        <div className="fixed inset-0 z-20  flex items-center justify-center bg-black bg-opacity-80">
          <div className="rounded-md">
            <UpdateAddressModal onClose={handleCloseModal} />
          </div>
        </div>
      )}

    
    </>
  );
};

export default Address;
