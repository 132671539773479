import {useState} from 'react'
import {toast} from 'react-toastify'
import {changePassword, sendCode,verifyCode, reset} from '../features/passwordReset/passwordResetSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
function PasswordReset (){
  const [phoneValue, setphoneValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verification  = useSelector(state => state.passwordReset.verification)
  const isSuccess = useSelector(state => state.passwordReset.isSuccess)   //replace this with the token content.


  const handleInputChangePhoneBox = (event) => {
    const newValue = event.target.value;
    if (/^\d{0,10}$/.test(newValue)) {
      setphoneValue(newValue);
    }
  };

  const handleInputChangeCodeBox = (event) => {
    const newValue = event.target.value;
    if (/^\d{0,6}$/.test(newValue)) {
      setCodeValue(newValue);
    }
  };

  
  const handleSubmitPhone = (event) => {
    event.preventDefault();

    dispatch(sendCode(phoneValue))
    toast.info(`If registered, you will receive a code via text message`)
   
  };

  const handleSubmitCode = (event) =>{
    event.preventDefault();
    
    const data = {
      phone: phoneValue,
      code: codeValue
    }
    dispatch(verifyCode(data))
    toast.info(`code sent` )
  }



  const [password1Value, setPassword1Value] = useState('')
  const [password2Value, setPassword2Value] = useState('')
  
  const [isResetPasswordClicked, setIsResetPasswordClicked] = useState(false);  


  const handlePassword1Change = (event) => {
    setPassword1Value(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2Value(event.target.value);
  };


  const handleResetPasswordClick = (event) => {
    event.preventDefault();
    setIsResetPasswordClicked(true); // set the state variable to true when the button is clicked.  I dont think I need this line at all
    // const validationSolution = validatePasswords(password1Value, password2Value); // call the function once when the button is clicked
    const isValidPassword = validatePasswords(password1Value,password2Value)

    if( !isValidPassword ){
    
      toast.error("New Password is not secure. Please try again")
    }else{
      setIsResetPasswordClicked(false);
      //send the password to the backend,
      const dataNewPassword ={
        token: verification.token,
        password : password1Value,
      }
      // put this in an wait, then navigate to home log in page
      dispatch(changePassword(dataNewPassword))
      
      //Reset's redux, which will also close the modal.
      dispatch(reset())
      
      //navigate to login page. 
      navigate("/login");
      //toast a successfull message
      //place to toast at the end of the function.
      toast.success("Passowrd has changed.")

      

    }
    
  };


  const validatePasswords = (password1, password2) => {
    const upperCaseRegex = /[A-Z]/;
    const digitCaseRegex = /\d/;
  
    if (
      password1.length < 8 ||
      password1 !== password2 ||
      !upperCaseRegex.test(password1) ||
      !digitCaseRegex.test(password1)
    ) {
      return false;
    }
    
    return true;
  };


  return (

    <>

    <div className='p-2 mt-5'>
      
    <div className="flex justify-start">
      <h1 className="text-3xl font-bold">Password Reset:</h1>
    </div>
      
    
    
      <form onSubmit={handleSubmitPhone} className="max-w-md mx-auto bg-slate-200 p-2 rounded-lg">
        <div className="flex flex-col justify-center items-center">
          <label className="text-md font-medium mb-2" htmlFor="phoneValue">
            10 digit phone number
          </label>
          <input
            className="rounded w-full md:w-1/2 py-2 px-3  focus:outline-none focus:shadow-outline"
            type="text"
            id="phoneValue"
            pattern="\d{10}"
            required
            value={phoneValue}
            onChange={handleInputChangePhoneBox}
          />
        </div>
        <button
          className=" bg-sky-500  font-medium py-2 px-4 rounded  mt-2"
          type="submit"
        >
          Get reset code
        </button>
      </form>

    {/* show this form only after  */}

      <form onSubmit={handleSubmitCode} className="max-w-md mx-auto mt-5 p-2 bg-slate-200 rounded-lg">
        <div className="flex flex-col justify-center items-center">
          <label className=" text-lg font-medium mb-2" htmlFor="codeValue">
            Enter code
          </label>
          <input
            className="border rounded w-full md:w-1/2 py-2 px-3  focus:outline-none focus:shadow-outline"
            type="text"
            id="codeValue"
            pattern="\d{6}"
            required
            value={codeValue}
            onChange={handleInputChangeCodeBox}
          
          />
        </div>
        <button
          className="bg-sky-500 font-medium py-2 px-4 rounded  mt-2"
          type="submit"
        >
          Verify Code
        </button>
      </form>
    </div>


    {/* Modal will appear on successful verification, if status is approved and isSuccess is true */}

      <div className={`fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center ${
          verification.status === "approved" && isSuccess ? "" : "hidden"
        }`}
      >
        {/* modal content goes here */}
        
        <form onSubmit={handleResetPasswordClick} >

          <div className="bg-white p-4 rounded-md flex items-center">
            {/* <div className='text-lg font-bold text-center mb-4'>
              Password Reset
            </div> */}
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-4"
              type="password"
              id="password1Value"
              required
              placeholder="Enter new password"
              value = {password1Value}
              onChange={handlePassword1Change}
            />
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-4"
              type="password"
              id="password2Value"
              required
              placeholder="Confirm new password"
              value={password2Value}
              onChange={handlePassword2Change}
            />
          </div>
          <div className='bg-white rounded-md'>
           <h1>New password must contain:</h1>
            <ul>
              <li>8 characters or more</li>
              <li>At least one digit</li>
              <li>At Least one lowercase or UPPERCASE letter</li>
            </ul>
          </div>

          <button
            
            className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
            type="submit"
          >
            Reset Password
          </button>



        </form>
        


      </div>



    </>
  
  );
}



export default PasswordReset