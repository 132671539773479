import { useSelector, useDispatch } from 'react-redux';
import { FaCog } from 'react-icons/fa';
import React, { useEffect }  from 'react';

import {  cancelSub } from '../features/subscription/subscriptionSlice';
import {useSubStatus} from '../hooks/useSubStatus'
// components 
import PricingTable from './stripe/PricingTable';

const SubscriptionDetails = () => {
    const dispatch = useDispatch();

    //sub info from the redux store
    const { subInfo } = useSelector(state => state.subscription);
    
   

    //sub tier from a custom hook
    const {subTier} = useSubStatus(); 


    useEffect(() => {
        console.log(subTier + " lalala")
    })

    const handleCancelSubscription = () => {       
        
        dispatch(cancelSub());
    }

    const handleModifySubscription = () => {
        console.log("The subscription will be modified.");
    }

    //also check if if status is not cancelled.
    //if the user had a subscription in the past, do not allow free trial.
    if (subInfo.subCount === 0 ) {
        return (
            // Prevent people from cancelling and reactivating their subscription to get a free trial
            <>

                <PricingTable style={{ position: 'relative', width: 'calc(130% + 20px)', left: '-10px' }}/>

             
            </>
        );
    } else {
        return (
            <>
                {/* the following information will be obtained not from redux, but from an axios call */}
                <div className='text-left'>
                    <h1 className='text-lg font-normal'>
                        Subscription Id:
                    </h1>

                </div>
               
                <div className="p-1 text-gray-600">
                    <p className="text-lg mb-2">Status:  <span className=" font-normal">{subInfo.subStatus} </span></p>

                    {/* convert epoc time to human readable time */}
                    <p>Tier: {subTier}</p>
                    <p className="text-md">Period Start: <span className="font-normal">{new Date(subInfo.subPeriodStart * 1000).toLocaleDateString()}</span></p>
                    <p className="text-md">Period End: <span className="font-normal">{new Date(subInfo.subPeriodEnd * 1000).toLocaleDateString()}</span></p>
                   
                    {/* show invoice history. use lazy loading with a dropdown arrow */}
                    <div className="text-left mt-4">
                        <h1 className='font-normal text-lg text-black'> Payment History:</h1>
                    </div> 
                                
                    <div className="flex justify-center space-x-4 mt-3">
                        <button
                            type="button"
                            className="bg-white border border-red-600 text-red-600 font-semibold py-1 px-2 rounded-lg"
                            onClick={handleCancelSubscription}
                        >
                            Cancel Subscription
                        </button>
                        <button
                            type="button"
                            className="group relative  text-black font-bold py-2 px-2 rounded-md"
                            onClick={handleModifySubscription}
                        >
                            <FaCog className="text-xl" />
                            <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 absolute top-0 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 transition-all duration-200">
                            Edit Subscription
                            </span>
                        </button>
                    </div>
                </div>

                <div className='mt-5 text-xs'>
                <p>Contact us at <a className='underline text-blue-700' href="mailto:support@timestat.app">support@timestat.app</a>, for any quesstions, concerns, or requests.</p>
                </div>
            </>
        );
    }
}

export default SubscriptionDetails;
