// This component displays the transactions a given worker had in on either a date range or last 8 days. 

function SiteItem({transaction}){

  //create a function takes in a time variable, 
  //this time variable is in utc time, return time in Eastern Standard Time
  //  The browser already does this conversion on chrome. 
  
  function timeFormat(databaseDate){

    if(databaseDate == null){
        let na = "N/A"
        return na ;
    }


    let dateObj= new Date(databaseDate)

    let hour = dateObj.getHours()
    let minute = dateObj.getMinutes();

    let time= `${hour}:${minute}`
    return time
  }
  
  function dateFormat(databaseDate){
        // let date= new Date(databaseDate).toISOString().substring(0,10)
        
        // return date
        

        let date = new Date(databaseDate);
       
        let month = date.getMonth()+1;
        let fecha = date.getUTCDate();
        let year = date.getFullYear();

        let customDate = `${month}/${fecha}/${year}`;
        return customDate;
    }
    function getHourDifference(startTime, endTime) {
        if(endTime == null){
            let unknown = "?"
            return unknown
        }else{
            let startDate = new Date(startTime);
            let endDate = new Date(endTime);
            let differenceInMilliseconds = endDate - startDate;
            let differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
            return Math.round(differenceInHours * 100) / 100;

        }

        
      }

    
    
    return(
        //change class name in CSS file
        <div className='ticket'>
            <div>
                <ul className="font-bold">
                    {dateFormat(transaction.date)}
                </ul>
                <ul>
                     {transaction.location.siteName}
                </ul>
               
            </div>
            <div>{timeFormat(transaction.timeIN)}</div>
            <div>{timeFormat(transaction.timeOut)}</div>
            <div className= {`${transaction.timeOut === null ? 'bg-red-200 rounded-lg' : ''}`} >{getHourDifference(transaction.timeIN, transaction.timeOut) }</div>
          

        </div>

    )
}

export default SiteItem