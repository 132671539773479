//rename this file to new finger device.
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createSite, reset } from '../features/sites/siteSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function NewSite() {
  const { user } = useSelector((state) => state.auth)
  const { isLoading, isError, isSuccess, message } = useSelector((state) => state.sites)

  const [userCompanyName] = useState(user.company)

  const [deviceCode, setDeviceCode] = useState('')


  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
      navigate('/sites')
    }
  }, [dispatch, isError, isSuccess, message, navigate])



  const handleCodeInput = (e) => {
    const input = e.target.value
    let formattedInput = input.replace(/[^A-Za-z0-9]/g, '').substring(0, 23)
    formattedInput = formattedInput.split('').reduce((result, char, index) => {
        return result + char + ((index % 2 !== 0 && index !== formattedInput.length - 1) ? ',' : '')
    }, '')
    setDeviceCode(formattedInput)
}


  const onSubmit = (e) => {
    e.preventDefault()
    if (!deviceCode ) {
        toast.error('All fields are required')
        return
      }
    dispatch(createSite({deviceCode}))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex'>
        <BackButton url="/homedash" />

      </div>
     
      <div className='mx-auto max-w-lg py-8'>
        <h2 className='text-4xl font-bold'>Enroll new finger device for  <span className='text-red-500'> {userCompanyName}</span> </h2>

        <p className="text-md leading-relaxed text-gray-700 my-4">
          To use an existing device at a new jobsite, fill out the form below.
        </p>
      </div>

    <div className='mx-auto max-w-3xl'>

    
      <form onSubmit={onSubmit}>
       

        <div className="form-group">
          <label htmlFor="deviceCode">Device Code</label>
          <input
            type="text"
            name="deviceCode"
            id="deviceCode"
            maxLength={23}
            className="form-control"
            placeholder="Press C on your device menu"
            value={deviceCode}
            onChange={handleCodeInput}
            required
          />
        </div>


        <div className="form-group">
          <button className="bg-black text-white p-2 rounded-lg" disabled={deviceCode.length !== 23}>Submit</button>
        </div>
      </form>
    </div>
    </>
  )
}

export default NewSite
