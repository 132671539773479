import axios from "axios";

// Get Customer subscription information route
const API_GET_CUSTOMER_SUB = '/api/sub/getCustomerSubscription'

//Good tier routes
const API_START_GOOD_MONTHLY_SUB = '/api/sub/createMonthlyGoodSubscription'
const API_START_GOOD_YEARLY_SUB = '/api/sub/createYearlyGoodSubscription'

//Better tier routes
const API_BETTER_MON = '/api/sub/createMonthlyBetterSubscription'
const API_BETTER_YEAR = '/api/sub/createYearlyBetterSubscription'
//Best tier routes
const API_BEST_MON = '/api/sub/createMonthlyBestSubscription'
const API_BEST_YEAR = '/api/sub/createYearlyBestSubscription'
//Cancel route
const API_CANCEL_SUB = '/api/sub/cancelSubscription'




const getSubscription = async (token) =>{
   

    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    
    const response = await axios.get(API_GET_CUSTOMER_SUB, config)

    return response.data
}

//start a good yearly subscription
const startGoodYearly = async (token) =>{
   

    const response = await axios.post(API_START_GOOD_YEARLY_SUB, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
    return response.data
}

//start monthly good tier subsciption
const startGoodMonthly = async (token) =>{
    const response = await axios.post(API_START_GOOD_MONTHLY_SUB, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

   
    return response.data
}


//start BETTER monthly subscription
const startBetterMonthly = async (token) =>{
    const response = await axios.post(API_BETTER_MON, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

   
    return response.data
}

//start BETTER yearly subscription
const startBetterYearly = async (token) =>{
    const response = await axios.post(API_BETTER_YEAR, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

   
    return response.data
}

//start BEST monthly subscription
const startBestMonthly = async (token) =>{
    const response = await axios.post(API_BEST_MON, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

   
    return response.data
}

//start BEST yearly subscription
const startBestYearly = async (token) =>{
    const response = await axios.post(API_BEST_YEAR, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

   
    return response.data
}


//cancel subscription
const cancelSub = async (token, subId) =>{
    console.log(token)
    console.log(subId)

    const response = await axios.post(API_CANCEL_SUB, {
        subId
    }, {
        headers: { Authorization: `Bearer ${token}` }
      });

   

    return response.data
}


const siteService = {
    getSubscription,

    startGoodMonthly,
    startGoodYearly,

    startBetterMonthly,
    startBetterYearly,

    startBestMonthly,
    startBestYearly,


    cancelSub,
}

export default siteService