//The worker dashboard will be the dashboard if the user is a worker and is logged in. 

//if the worker has not been onboarded yet, the onboarding components will be rendered. 
//if the worker is onboarded, the jobsites the worker is assigned to will be rendered for
//for the company he is working for

import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {toast} from 'react-toastify'
import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import WorkerOnboarding from '../WorkerOnboarding';
import LogOutWorker from '../../features/workerAuth/workerAuthSlice'

function WorkerDash() {
  //from redux store get if the worker has been onboarded. 

  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  const dispatch = useDispatch()

  const {workerUser}= useSelector(state => state.workerAuth)
 

  // get the user's location, if permission is not granted toast an error message that location is required to used the app
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      }, (error) => {
        console.log(error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);


  return (
    <>


    {/* check if onboarded is strictly false, because if its null it can mean there is no user object */}
    {workerUser.onboarded === false ?  <WorkerOnboarding /> : 
      <>
        <div className="p-4">
          <h2 className="text-4xl font-bold"> {workerUser.workerName} {workerUser.workerLastName}
        <span className='text-red-500'> </span></h2>
        </div>

        {/* show location user location  */}
        {/* Show jobsites the worker is assigned to and he can click into  */}
        <div>
          <p>latitude: {latitude}</p>
          <p>longitude: {longitude} </p>
        </div>

      </>
    
    }
    
      

   
  
     
    </>
  );
}

export default WorkerDash;
