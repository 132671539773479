//the component will return the latitude, longitude and address of the selected location to NewSiteForm
//the user can either click on the map or type in the address input


import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

//onLocationChange is a callback function that will be used to update the state in NewSiteForm
function GoogleMapsComponent({onLocationChange}) {
  const mapRef = useRef(null);

  useEffect(() => {
    let marker = null;  

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 40.7128, lng: -74.0060 },
        zoom: 11,
      });

      // Create an autocomplete input
      const input = document.getElementById("autocomplete-input");
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      
      // Set the bounds to the map's viewport for better results
      autocomplete.bindTo("bounds", map);

      // Add a listener to handle the place changed event
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();

          if (!place.geometry) {
            console.error("Place details not found for input:", input.value);
            return;
          }

          const { location } = place.geometry;
          
          const lat = location.lat();
          const lng = location.lng();
          const addr = place.formatted_address;


          console.log(`Selected in the input field ${addr} - Latitude: ${lat}, Longitude: ${lng}`);
          
          // console.log("The place ID is : "+ place.place_id)
          
          //set the map's center and zoom to the selected location
          map.setCenter(location);
          map.setZoom(15);

    
          if(marker){
              marker.setMap(null);
            }

            //place a marker on the typed location 
            marker = new window.google.maps.Marker({
                  position: location,
                  map,
                  // title:"New Site"
            });

            //update the state in NewSiteForm by calling the callback function passed as a prop
            onLocationChange({lat,lng,addr});

        });


      ////////////////////////////////////////////////////////////

      //listener for map click event

      map.addListener("click", (event) =>{
        
        const clickedLocation = event.latLng;
        const lat = clickedLocation.lat();
        const lng = clickedLocation.lng();
 
        if(marker){
          marker.setMap(null);
        }
        //place a marker on the clicked location 
        marker = new window.google.maps.Marker({
          position: clickedLocation,
          map,
        });

        //use the geocode api to get the address of the clicked location
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({location: clickedLocation}, (results, status)=>{
          if(status === "OK"){
            if(results[0]){
              const addr = results[0].formatted_address;
              console.log(`Clicked on the map - Latitude: ${lat} || Longitude: ${lng} || Address: ${addr} `)
              
              //update the state in NewSiteForm by calling the callback function passed as a prop
              onLocationChange({ lat, lng, addr});
              // Set the value of the autocomplete input to the address
              input.value = addr;
            }else{
              window.alert("No results found");
            }
          }else{
            window.alert("Geocoder failed due to: " + status);
          }
        })

        // onLocationChange({ lat, lng, addr: null});
        // console.log(`Clicked on the map - Latitude: ${lat} || Longitude: ${lng} `)

      });
    });


  }, []);   //maybe remove add on location change

  return (
    <div className="mb-2 ">

      <div className="mt-2 mb-2">
        <input
          id="autocomplete-input"
          type="text"
          placeholder="Type Address or Click on Map"
          style={{ width: "300px", padding: "10px" }}
          className="rounded"
        />
      </div>
      <div ref={mapRef} style={{ height: "50vh", width: "100%" }}></div>
      
    </div>
  );
}

export default GoogleMapsComponent;