//from redux get the stat of the current user name, company and email. Do not make a new call to the database. 
import { useSelector } from "react-redux";
const ContactInfo = () => {
    //from redux we get the user information. 
    const user = useSelector((state) => state.auth.user)
    //have a state for the phone number.
    if(!user){
        return null;
    }

    //function get address from stripe 
    



  return (
    <>
        <div className="text-left">
          <h2 className="text-lg font-normal">Contact Information</h2>

        </div>
        
        
        <div className='max-w-3xl'>
            <ul className=" font-sans text-md text-gray-600"> 
                <li className="capitalize">{user.company}</li>
                <li>{user.email}</li>
                <li className="capitalize">{user.name}</li>
                {/* get the phone and address from the backend, show it here as well*/}
            </ul>

            <button className="mt-2 px-4 py-1 bg-green-500 rounded text-sm font-sans">Update Contact</button>
            </div>
    
    </>
 
  );
};

export default ContactInfo;
