//task is to get job sites beloning to an user, from the backend 

import axios from "axios";

const getJobSites_API = '/api/dash/getJobSites'

const getJobSites = async (token) => {
  
    try {
        const response = await axios.get(getJobSites_API, {
            headers: { 
                 'Authorization': `Bearer ${token}`
                },  //send token to backend
        });
        
        return response.data;
    } catch (error) {
        console.log("Error fetching the user's jobSites" + error);
        throw error;
    }
}

export default getJobSites;