import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {loginWorker} from '../features/workerAuth/workerAuthSlice'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import Spinner from '../components/Spinner' 
import {Link} from 'react-router-dom'
import {FaClock} from 'react-icons/fa'



function WorkerLogin (){
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')    


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {workerUser, isError, isSuccess, isLoading, message} = useSelector(state => state.workerAuth)

    useEffect(()=>{
        //if there is an error show the error message via
        if(isError){
            
            toast.error(message)
        }
        //if the user is logged in and is a worker redirect to the worker dashboard.
        if(isSuccess || workerUser){
             
            navigate('/workerDash')
        }


    },[isError,isSuccess,workerUser,message,navigate,dispatch])

    const onChange = (e) => {
        setPassword(e.target.value);
    }
    const stylePhoneNumber = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, ""); // Remove all non-numeric characters
        value = value.substring(0, 10); // Limit to max 10 digits

        // Add dashes
        if (value.length > 6) {
            value = value.substring(0, 3) + "-" + value.substring(3, 6) + "-" + value.substring(6);
        } else if (value.length > 3) {
            value = value.substring(0, 3) + "-" + value.substring(3);
        }

        setPhoneNumber(value);
    }   



    const onSubmit = (e)=>{
        e.preventDefault()
        
        //remove the dashes and submit to backend
        const workerPhone = phoneNumber.replace(/-/g, "")
        
        const workerCredentials = {
            phoneNumber : workerPhone,
            password
        }

        dispatch(loginWorker(workerCredentials))
        if(isSuccess){
            navigate('/workerDash')
        }
       
    }

  
    if(isLoading){
        return <Spinner/>
    }
    return(
        <>
        <div className="min-h-screen bg-gray-100 flex items-center
             justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <FaClock className="mx-auto h-12 w-auto"/>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Worker Clock In
                    </h2>
                </div>

                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="phoneNumber" className="sr-only">Phone Number</label>
                            
                            <input 
                                id="phoneNumber" 
                                name="phoneNumber" 
                                type="text" 
                                value={phoneNumber} 
                                onChange={stylePhoneNumber} 
                                required 
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                     text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                placeholder="(347)123-1234" 
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input 
                                id="password" 
                                name="password" 
                                type="password" 
                                value={password}
                                onChange={onChange} 
                                required 
                                className="appearance-none rounded-none 
                                    relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 
                                        text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500
                                            focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Password" />
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="group relative w-full flex justify-center
                         py-2 px-4 border border-transparent text-sm font-medium rounded-md
                             text-white bg-red-600 hover:bg-red-700 focus:outline-none">
                            Log In
                        </button>
                    </div>
                </form>
                <div className="mt-6">
                    <Link to="/resetPassword" className="text-indigo-600 hover:text-indigo-500">
                        Forgot Password?
                    </Link>
                </div>
            </div>
        </div>


            
        </>
    )
}

export default WorkerLogin