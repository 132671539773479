import React from 'react';

const callouts = [
  {
    name: 'Pen and Paper',
    description: 'An outdated method prone to human error, not suitable in the digital age. Neither secure nor fraud-free, the attendance records may be easily altered without detection.',
    imageSrc: '/frustratedMan.jpeg',
    imageAlt: 'Frustrated Man',
    href: '#',
  },
  {
    name: 'ID cards and Key fobs',
    description: 'Easily forgotten, stolen or duplicated... They can be loaned to someone else, which makes it difficult to track who is actually using them. When lost or damaged, issuing a new one requires special equipment, adds to costs and time.',
    imageSrc: '/keyfobs_idcards.png',
    imageAlt: 'Key Fobs and ID Cards',
    href: '#',
  },
  {
    name: 'Manager',
    description: "Inefficient, as it takes time away from your manager's other tasks. Having a person handle attendance tasks opens the door to human error.",
    imageSrc: '/managerInstructing.jpeg',
    imageAlt: 'Manager Instructing',
    href: '#',
  },
];

export default function OldWay() {
  return (
    <div className="bg-slate-300 mt-4 pt-2">
     
        <div className="mx-auto  md:max-w-none lg:max-w-screen-lg lg:py-32">
          <h2 className="text-3xl md:text-5xl font-mono mb-2 text-center text-red-600">Avoid headaches & Save Time</h2>

          <div className="mt-6 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-2">
            {callouts.map((callout) => (
              <div key={callout.name} className="group relative rounded-xl overflow-hidden  bg-slate-300">
                <div className="p-4 h-80 w-full rounded-lg overflow-hidden ">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="p-4">
                  <h3 className="mt-2 text-xl font-semibold">
                    <a href={callout.href} className=" hover:underline">
                      {callout.name}
                    </a>
                  </h3>
                  <p className="mt-2 text-gray-600">{callout.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
     
    </div>
  );
}
