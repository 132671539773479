import BackButton from '../components/BackButton';
import {useDispatch, useSelector } from 'react-redux'
import RegistryItem from '../components/RegistryItem';
import { useEffect } from 'react';
import Spinner from '../components/Spinner'
import {getRegistry} from '../features/registry/registrySlice'
import {toast} from 'react-toastify'
import { useParams } from 'react-router-dom';
import AddButton from '../components/AddButton'



function Registry(){
    
    const {siteInfo}= useSelector((state)=> state.sites) 
    const {registryInfo, isLoading, isError, message } = useSelector((state) => state.registry )

    const dispatch= useDispatch()
  
    const {siteId} = useParams()

    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
       

        dispatch(getRegistry(siteId))
        
    },[dispatch, isError, message, siteId])




    //1   get the site
    
   if(isError){
        if(registryInfo[0] == null){
            return(
            <>  
                <BackButton url='/sites'></BackButton>
                <h3>This site is Empty</h3>
                {/* Add componenet to add new worker */}
                <section>
                     <AddButton url={`add`} />
                 </section>
            </>)
        }else
        return(<h3>Something Went Wrong</h3>)
   }

    if(isLoading){
        return <Spinner/>
    }

    

    return(
        <>
        <section>
            <BackButton url='/sites'  />
        </section>
         <h1>Workers assigned to {siteInfo.siteName}  <span className='bg-yellow-200'>DEV ID:  {siteInfo.uniqueID} </span>  </h1>

         <p> Unlocked Status allows a worker to enroll to a device using his phone number. After enroll is complete, lock it for security. </p>


        <section>
            <AddButton url={`add`} />
        </section>
         <div className="tickets">
            <div className='ticket-headings'>
                <div>Worker Name</div>
                <div>Worker Phone</div>
                <div>onSite ID</div>
                <div>Status</div>

            </div>  
            {registryInfo.map((worker) =>(
                                            <RegistryItem key={worker.workerID} worker={worker} />
                                         )
                            )}   
            </div>
        </>
           
    
    )
}


export default Registry