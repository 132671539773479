//A custom hook to check which user is logged in. WorkerUser or User
//This will be used to grant access to the dashboard based on the user type.

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useAuthStatus = () => {
  
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)
  const [workerLoggedIn, setWorkerLoggedIn] = useState(false)

  //get the user from the redux store
  const { user } = useSelector((state) => state.auth)
  const { workerUser} = useSelector((state) => state.workerAuth)
  
  //this is why sometimes the app allows the user to access the dashboard even when they are not logged in. 
  //because  sometimes in the browser storage the user is not deleted, or contains old token that is not valid anymore. 
  
  
  useEffect(() => {
    if(workerUser){
      setWorkerLoggedIn(true)
      setLoggedIn(false)
    }else{
      setWorkerLoggedIn(false)
    }


    if (user) {
      setLoggedIn(true)
      setWorkerLoggedIn(false)
    } else {
      setLoggedIn(false)
    }
    //set checking status to false after checking the user status
    setCheckingStatus(false)
  }, [user, workerUser])  //this useEffect will run when the user or workerUser changes.

  return { loggedIn, checkingStatus, workerLoggedIn }  //makes the loggedIn, checkingStatus and workerLoggedIn available to the component that uses this hook.
}
