

import subscriptionService from './subscriptionService' 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
   
    subInfo: {},

    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',

}

//Functions to handle async actions for the subscription slice.

//get subscription information from a user
export const getSubscription = createAsyncThunk('subscription/get', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.getSubscription(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a monthly subscription good tier
export const goodMo = createAsyncThunk('subscription/startGoodMonthly', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startGoodMonthly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a yearly subscription good tier
export const goodYr = createAsyncThunk('subscription/startGoodYearly', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startGoodYearly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a better monthly subscription
export const betterMo = createAsyncThunk('subscription/startBetterMonthlySub', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startBetterMonthly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a better yearly subscription
export const betterYr = createAsyncThunk('subscription/startBetterYearlySub', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startBetterYearly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a best monthly subscription
export const bestMon = createAsyncThunk('subscription/startBestMonthlySub', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startBestMonthly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

//action to start a best yearly subscription
export const bestYr = createAsyncThunk('subscription/startBestYearlySub', 
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await subscriptionService.startBestYearly(token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})


//action to cancel a subscription 
export const cancelSub = createAsyncThunk('subscription/cancel',
    async( _, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            const subId = thunkAPI.getState().subscription.subInfo.subID
            return await subscriptionService.cancelSub(token, subId)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
    })


export const subscriptionSlice = createSlice({
    name:'subscription',
    initialState,
    reducers:{
        reset: (state) => initialState,
    },
    extraReducers: (builder) =>{
        builder 
            .addCase(getSubscription.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(getSubscription.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.subInfo = action.payload
            }) 
            .addCase(getSubscription.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(goodMo.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(goodMo.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(goodMo.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(goodYr.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(goodYr.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(goodYr.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(betterMo.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(betterMo.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(betterMo.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(betterYr.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(betterYr.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(betterYr.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(bestMon.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(bestMon.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(bestMon.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(bestYr.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(bestYr.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            }) 
            .addCase(bestYr.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(cancelSub.pending, (state)=>{
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(cancelSub.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subInfo = action.payload
            })
            .addCase(cancelSub.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
            
            
})

export const  {reset} = subscriptionSlice.actions
export default subscriptionSlice.reducer