import {useDispatch} from 'react-redux'

import {toggleRegistryStatus} from '../features/registry/registrySlice'
function RegistryItem({worker}){

    const dispatch = useDispatch();
   
    let toggleData = {
        workerId: worker.workerID,
        devId: worker.devID,
    }

    //Add on click Event to refresh page. 

    return(
        //change class name in CSS file
        <div className='ticket'>
            <div> 
                {worker.worker.name} {worker.worker.lastName}
             </div>
           
           <div>    
           {`${worker.worker.phone.slice(0, 3)}-${worker.worker.phone.slice(3, 6)}-${worker.worker.phone.slice(6)}`} 
           </div>
           
           <div>
                    {worker.fingerID}
           </div>

            {/* on click dispatch status toggle.  */}
           <button className={`status btn status-${worker.lockStatus }`} 
            onClick={() => {
               
                dispatch(toggleRegistryStatus(toggleData))  //In backend return just true or false.  Apply this reducer's locks status only. 



           }} >
                {worker.lockStatus? (
                   <p>Locked</p>
                ) : (<p>Unlocked</p>)}
            </button>
        </div>

    )
}

export default RegistryItem