export default function Intro() {
  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto">
        <div className="relative z-10 bg-white pb-8 sm:pb-8 md:pb-20 lg:w-full lg:max-w-xl lg:pb-28 xl:pb-32">

          <main className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl font-bold font-mono  text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block  text-red-600"> Fraud-Free</span>
                <span >Attendance</span>
               
              </h1>
              <p className="mt-5 text-base text-gray-500 sm:text-md md:mt-5 md:text-xl lg:mx-0">
              An ultra-fast, simple and secure biometric attendance system for fraud-proof time tracking <span className="font-bold" > without the need of WiFi.</span> Its compact size allows you to place it any workplace environment.
              Our App, allows you to generate detailed reports to better understand the attendance habits of your employees.
              
              </p>
              <div className="mt-4 flex justify-center">
               
                  <a
                      href="https://player.vimeo.com/video/850412571?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      className=" bg-red-600 text-white rounded-lg px-4 py-2  md:text-xl font-semibold border hover:border-black"
                    >
                      Demo
                    </a>
                
                
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
          src="/TimeStatDev.svg"
          alt="timeStat Device"
        />
      </div>
    </div>
  )
}

