import { FaArrowRight} from 'react-icons/fa'

 import { useDispatch } from 'react-redux'
 import { transactionsOnSiteDateFiltered } from '../features/transactions/transactionsSlice'

// Takes in a Date obj prop.
const DateFilterButton = ({dateObj}) => {
   
    //FrontEnd - convert DateObj to ISOS Format, used by database. 
    const date = new Date(dateObj)

    const month = date.getMonth()
    const year = date.getFullYear()
    const dia = date.getDate()

    const dateInput = new Date(Date.UTC(year,month,dia))

    const dispatch = useDispatch()

    return(
        <button className="flex items-center bg-green-500 hover:bg-green-400 rounded-lg text-lg p-1 ml-2" 
        
        onClick={ ()=>{
            
            // Stringify dateInput and dispatch it to the backend. 
            let dateStr = dateInput.toISOString()
            
            dispatch(transactionsOnSiteDateFiltered({dateStr}))
        
        }}
        
        >
            
            <FaArrowRight className=" text-xl mr-1" /> Search
        </button>
    )
}

export default DateFilterButton