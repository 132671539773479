
import { Link } from 'react-router-dom';
import { FaUsers , FaCog} from 'react-icons/fa';
import {MdHomeWork } from 'react-icons/md';
import { useSelector,useDispatch } from 'react-redux';
import {toast} from 'react-toastify'
import {getSubscription} from '../features/subscription/subscriptionSlice'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { BsBuildingGear } from "react-icons/bs";
function Homedash() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {subInfo} = useSelector((state) => state.subscription);


  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);

  const handleCreateJobSite = () =>{
    if(subInfo.subCount > 0){
      navigate('/newJobSite')
    }else{
      toast.error('You need to subscribe to create a JobSite')
    }
     
  }

  return (
    <>
      <div>
          <h1 className="hidden md:block text-4xl font-normal mb-4">Welcome {user && user.company}</h1>
      </div>
   
    {/* App Pages */}
        <div className="flex flex-col  justify-center md:flex-row md:flex-wrap  gap-4 p-2">

          <div className="p-2 my-2 w-full md:w-64">
              <Link to="/jobsites" className="flex items-center group  hover:text-red-700">
              <MdHomeWork className='text-2xl mr-2  text-slate-600' />JobSites   
                <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 absolute top-0 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 transition-all duration-200">View and manage all your jobsites</span>
            </Link>
          </div>
          
          {/* <div className=" p-2 my-2 w-full md:w-64">
            <Link to="/sites" className="flex items-center group hover:text-red-700">
            <BsBuildingGear className="text-3xl mr-2 text-slate-600" /> Field Devices  
              <span className="opacity-0 bg-indigo-700 text-white text-md 
              rounded-md py-1 px-2 absolute top-0 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 
              group-hover:translate-y-20 transition-all duration-200">Manage your field devices, enroll worker's fingerprints.</span>
            </Link>
          </div> */}

         

          <div className="p-2 my-2 w-full md:w-64">
             <Link to="/workers" className="flex items-center hover:text-red-700">
            <FaUsers className="text-3xl mr-2  text-slate-600" /> Workers
             <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 absolute top-0 left-1/2 transform
            -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 
            transition-all duration-200">Manage your workers.</span>
            </Link>
          </div>

          
         

          
          {/* <div className="p-2 my-2 w-full md:w-64 ">
            <Link to="/orderDevice" className="flex items-center hover:text-red-700">
              <FaCartPlus className="text-3xl mr-2  text-slate-600" />Purchase Field Device
              <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 absolute top-0 left-1/2 transform
               -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 transition-all duration-200">Field Devices
                can store store up to 200 workers fingerprints, do not depend on Wi-Fi, and can be activated/deactivated anytime.</span>
            </Link>
          </div> */}
          

          <div className="p-2  my-2 w-full md:w-64 ">
            <Link to="/userInfo" className="flex items-center group hover:text-red-700">
              <FaCog className="text-2xl mr-2  text-slate-600" /> Settings
              <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md
               py-1 px-2 absolute top-0 left-1/2 transform -translate-x-1/2 
               group-hover:opacity-100 group-hover:translate-y-20 transition-all 
               duration-200">Modify subscription settings.</span>
            </Link>
          </div>

   

          



        </div>


      

     
    </>
  );
}

export default Homedash;
