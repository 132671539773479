//this page renders if a worker is not onboarded yet. 
// meaning the worker still has a temporary password and must complete onboarding. 




import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'


import {useNavigate} from 'react-router-dom'

// import {Link} from 'react-router-dom'

import PasswordStep from '../components/workerOnboarding/PasswordStep'
import SsnStep from '../components/workerOnboarding/SsnStep'
import AddressStep from '../components/workerOnboarding/AdddressStep'
import ProfilePictureStep from '../components/workerOnboarding/ProfilePictureStep'
import FacialLiveness from '../components/FacialLiveness'

function WorkerOnboarding (){
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [step, setStep] = useState(1);
    const [password, setPassword] = useState("");
    const [ssn, setSsn] = useState("");
    const [address, setAddress] = useState({});
    const [sessionId, setSessionId] = useState(""); //this will be used to store the session id used for facial recognition.

    //from redux store get the worker auth token 
    const {workerUser, isError, isSuccess, isLoading, message} = useSelector(state => state.workerAuth)


    //this will be passed to the backend to update the worker data.
    const onboardData = {
        address: address.address,
        zipcode: address.zipcode,
        state: address.state,
        taxId: ssn,
        livenessSession: sessionId,
        newPassword: password,
    }


    // Functions to be passed to child components
    
    //take password from the child component and set it to the state. So it can be passes to the backend later.
    const handleSessionIdChange = (sessionId) => {
       setSessionId(sessionId)
       
    };

    //set the password to the state
    const handleSetPassword = (password) => {
        setPassword(password)
       
    };


    //set the ssn/itin to the state
    const handleSetSSN = (ssn) => {
        setSsn(ssn)
       
    };

   
    //set the address to the state
    const handleSetAddress = (childAddress) => {
        setAddress(childAddress)
    };

  
    //submits the data to the backend to be store in the database
    const submitData = () =>{
        //for now log the data to the console
        console.log(onboardData);
        // dispatch(workerData)

        //navigate to the worker dashboard
        // navigate('/homedash')
    }


    const nextStep = () => {
    // if the step is less than 4, increment the step by one
        if (step < 4) {
          setStep(step + 1);
        } 
        else  {
            // submits the gathered data to the backend
            submitData();  
        }
    };
    
    return(
    <>
        {/* show the worker name, get it from redux */}
        <div>
            <h1 className='text-xl font-bold text-center mt-5'> {workerUser.workerName} {workerUser.workerLastName}</h1>
            <p className='text-xl font-normal text-center mb-5'> {step}/4</p>

        </div>


        <div>
            {/* Step 1, verify real person and get worker picture, and sessionID */}
            {step === 1 &&  (
                 <ProfilePictureStep nextStep={nextStep} onSessionIdChange={handleSessionIdChange}    />
             
            )}

            {step === 2 && <PasswordStep setPassword={handleSetPassword} nextStep={nextStep} />} 

            {step === 3 && <SsnStep setSsn={handleSetSSN} nextStep={nextStep} />} 

            {/* type the address and submit the data when button is pressed */}
            {step === 4 && <AddressStep setAddress={handleSetAddress} nextStep={nextStep} />} 
            

           
        </div>
       
     
    
            
    </>
    )
}

export default WorkerOnboarding;