import axios from 'axios'



const API_URL_LOGIN = '/api/worker/logIn'



//login the woeker and store the worker in the local storage
const loginWorkerService = async(workerCredentials) =>{
    
    const response = await axios.post(API_URL_LOGIN,workerCredentials)

    if(response.data){
        localStorage.setItem('workerUser',JSON.stringify(response.data))
    }

    // console.log(response.data)
    return response.data
}


//logOut
//Does not return a promise, just removes the workerUser from local storage
const logoutWorkerService = () => localStorage.removeItem('workerUser')










const workerAuthService = {
    logoutWorkerService,
    loginWorkerService,
   
}


export default workerAuthService