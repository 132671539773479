import axios from "axios";
import { toast } from 'react-toastify'

const API_CREATEWORKER = 'api/dash/newWorker'
const API_GETWORKERS = '/api/dash/workers'
const API_GETWORKER = 'api/dash/worker/'
const API_UPDATEWORKER = '/api/dash/updateworker' //absolute path 
const API_TERMINATEWORKER = '/api/dash/terminateWorker' //absolute path


//create new worker 
const createWorker = async (workerData, token) =>{
    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_CREATEWORKER, workerData, config)

    return response.data
}

//get all workers belonging to a user
const getWorkers = async(token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETWORKERS, config)

    console.log(response.data)  
    return response.data

}  



//get Single Worker Info 
const getWorker = async(workerID, token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETWORKER + workerID, config)
    return response.data

}  

//update a single worker
const updateWorker = async(updateData, token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_UPDATEWORKER , updateData  , config)
    
    return response.data

} 


//terminate a worker
//updates the redux store workers slice with the new worker data.
const terminateWorker = async( workerID, token) => {
    
   //worker ID is just a the id #
   const workerData = {
    workerID : workerID
    }

    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

   
    const response = await axios.put(API_TERMINATEWORKER , workerData  , config)
    
  
    if(response.status === 200){
        toast.success("Worker removed")
    }
   
    return response.data

} 


const workerService = {
    createWorker,
    getWorkers,
    getWorker,
    updateWorker,
    terminateWorker,
}
export default workerService

