import { useState } from "react";

import { FaCog } from "react-icons/fa";
import NotesInCards from "./NotesInCards";
import { FaUserClock } from "react-icons/fa";
import { SlNote } from "react-icons/sl";

import { AiOutlineCalendar } from "react-icons/ai";


//the onDelete and onToggleStatus are functions that are passed down from the parent component(JobSites.jsx)
const JobSiteCard = ({ jobSite, onDelete , onToggleStatus }) => {
 
    const [showDropdown, setShowDropdown] = useState(false);
    const [editNotesClicked, setEditNotesClicked]= useState(false);

    //modal states
    const [workerAssignmentModal, setWorkerAssignmentModal] = useState(false);

    //toggle the edit notes button
    const handleEditNotes = () => { 
        setEditNotesClicked(!editNotesClicked);
       
    }

    const handleCogClick = () => {
     
      setShowDropdown(!showDropdown); 

    }

    //this function will require the user token and the jobsite id;
    const handleJobsiteStatusToggle = () =>{
      onToggleStatus(jobSite.id);
    }

    //show a modal which will allow the user to assign a field device to a jobsite
    const handleAssignFieldDevice = () => {
      console.log("The assign field device button was pressed")

    }

    //sets the modal to true, which will reaveal the worker assignment modal
    const handleAssignWorkerModalToggle = () =>{

        // Toggle  the worker assignment modal
        setWorkerAssignmentModal(!workerAssignmentModal);
       
    }

  

    const handleShowAtendance = () => { 
      
      console.log("The attendance button was pressed")
    }

    //deletes the jobsite from the database. 
    const handleDeleteJobsite =  () => { 
      //calls the parent component's onDelete function which will delete the jobsite from the database.
      onDelete(jobSite.id);
    }

    const handleShowBuilding = () => { 
     
      console.log(jobSite.id)
    }

    const handleSelectSupervisor = () => {
      console.log("The assign supervisor button was pressed");
    }

    return (
    <>
      <div className="mb-10 rounded-md border border-black w-full sm:w-80 max-w-md mx-auto  bg-gradient-to-b from-black from-10% via-slate-600 via-40% to-slate-100 to-70%">
        
        {/* button to toggle the jonsite status to true or false */}
        <div className="flex justify-between">
          {/* display the status as an h1 */}
          <div>
            <h1
              className={`ml-2 mb-0 mt-1 text-white bg-black rounded font-mono font-bold px-2 py-1`}
            >
              {jobSite.jobsiteName.toUpperCase()}
            </h1>

          </div>
        

          <div className="relative">
            
            <div>
              <button
                  className="mt-2 mr-2 transform hover:scale-150"
                  onClick={handleCogClick}
                > 

                  <FaCog className="text-white"/>
                </button>

            </div>
            
            {/* COG Dropdown  */}

              {showDropdown && (
                <div className="absolute right-0 mt-2 z-10"> 
                  <div className="w-48 rounded shadow-lg ring-1 ring-black ring-opacity-5">

                      <div className="py-1 bg-white rounded-lg" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                          

                         
                          <div className="rounded  hover:bg-slate-100"> 
                            <button
                              className="block px-4 text-md text-black"
                              role="menuitem"
                              onClick= {handleSelectSupervisor}
                            
                            >
                              Assign Supervisor
                  
                            </button>
                          </div>

                          <div className="rounded  hover:bg-slate-100"> 
                            <button
                              className="block px-4 text-md text-black"
                              role="menuitem"
                              onClick={handleAssignFieldDevice}
                            >
                              Assign Field Device
                  
                            </button>
                          </div>

                          <div className=" rounded hover:bg-slate-100"> 
                          
                          </div>
                          <div className="border border-red-600"> 
                            <button
                              className="block px-4 text-red-600 font-semibold"
                              role="menuitem"
                              onClick={handleDeleteJobsite}
                            >
                            Delete Jobsite
                            </button>
                          </div>
                        
                      </div>


                  </div>
                  
                </div>
              )}

          </div>
        
        </div>
          {/* image of jobsite */}
          <div className="relative p-3" >
            <img src={jobSite.s3URL ? jobSite.s3URL : "/constructionSite.png"} alt="Job Site" className="w-full h-64 object-cover rounded-md cursor-pointer" />

              {/* Place map marker at the borrom right */}
              {/* <div className="absolute bottom-2 right-2">
                  <FaMapMarkerAlt className="text-sm text-red-600 cursor-pointer" onClick={toggleMap} />
              </div> */}
          </div>

        
            {/* JobSite Name and Address */}
            <div className="mb-4">
              {/* <h1 className=" text-lg font-bold font-mono cursor-default  mb-0">{jobSite.jobsiteName}</h1> */}
              <p 
              className="text-gray-700 text-sm mt-0 font-semibold hover:underline cursor-pointer" 
                  onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(jobSite.address)}`, "_blank")}>
                  {jobSite.address}
            </p>
            </div>

            {/* notes section */}
          <div className="relative mb-4">
            <h1 className="text-md font-sans">Notes</h1>
            {editNotesClicked ? (
              <NotesInCards jobSite={jobSite} setEditNotesClicked={setEditNotesClicked} />
              ) : (
              <div className="text-sm rounded-lg p-0.5 border-b-4 overflow-y-auto max-h-32 h-32 cursor-default " >
                {jobSite.siteNotes ? (
                  Object.entries(jobSite.siteNotes).map(([key, value]) => (
                  <p key={key} className="text-gray-800 font-thin text-left">
                      <span className="mr-1 font-normal"> {`${key}:`} </span>{`${value}`}</p>
                  ))
              ) : (
                <p className="text-gray-800 font-mono">No Notes</p>
                )}
            </div>
            )}
        </div>

          {/* Row of icons */}
        
          {!editNotesClicked && (
         

            <div className='flex justify-between  mb-4 px-10'>
              <button className='relative group' onClick={handleShowAtendance}>
                <AiOutlineCalendar className="text-2xl  cursor-pointer text-red-600" />
                    <span className="absolute top-[-40px] p-0.5 right-6  flex items-center justify-center
                      opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                        text-white text-xs rounded-md  font-thin">
                    Daily Log
                    </span>
              </button>
              <button className='relative group' onClick={handleShowBuilding}>
                <FaUserClock className="text-2xl  cursor-pointer text-red-600" />
                  <span className="absolute top-[-50px] p-0.5  flex items-center justify-center 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                      text-white text-xs rounded-md font-thin">
                  Worker Attendance
                  </span>
            </button>
              <button className="relative group" onClick={handleEditNotes}>
                <SlNote className="text-2xl cursor-pointer text-red-600" />
                  <span className="absolute top-[-40px] p-0.5 left-6  flex items-center justify-center 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-600
                      text-white text-xs rounded-md  font-thin">
                      Post Jobsite Note
                    </span>
              </button>
            </div>
          )}


          {/* Modals */}
          
         


          </div>
    </>

    );
  }
  
  export default JobSiteCard;
