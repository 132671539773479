  import BuyButtonComponent from "./BuyButtonComponent"
  export default function Pricing() {
      
    return (

    <>
        <div className="  bg-slate-300 p-2 rounded-lg">
        <h2 className="text-3xl md:text-5xl text-red-600 font-mono">Simplified Pricing</h2>
        
            <div className=" rounded-[24px]  ">

            
                <div className="my-4 bg-black text-green-600 rounded-[16px] p-2 max-w-lg mx-auto">
                        <span className="text-5xl  font-bold">$30</span>
                        <span className="text-sm   font-bold">/Active site/Month</span>
                    
                    <ul className="list-none  text-gray-200">
                        <li>3 years of cloud storage</li>
                        <li>200 Workers per site</li>
                        <li>Reveal Worker Performance Analytics </li>
                        <li>Cancel Anytime, no Contracts</li>
                    </ul>
                </div>

                <div className="my-4 bg-black text-green-600 rounded-[16px] p-2 max-w-lg mx-auto">
                        <span className="text-5xl  font-bold">$199</span>
                        <span className="text-sm  font-bold">/device</span>
                    
                    <ul className="list-none  text-gray-200 ">
                        <li>One-time charge, per new device </li>
                    </ul>

                        <BuyButtonComponent/>
                    
                    
                </div>
        </div>
        
        </div>


    </>


     
    )
  }
  


