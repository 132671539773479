import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import workerAuthService from './workerAuthService' 

//get user from local storage
const workerUserAuth = JSON.parse(localStorage.getItem('workerUser'))

const initialState = {
    // if there is no user in local storage, set to null
    //workerUser: workerUserAuth? workerUserAuth : null,
    workerUser: workerUserAuth,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

//login Worker user
export const loginWorker = createAsyncThunk('workerUser/login', 
    async(workerUserCredentials, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
         try {
            console.log(workerUserCredentials)  
            return await workerAuthService.loginWorkerService(workerUserCredentials)
         } catch (error) {
            // if there is an error return the message from the response or the error message
            const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            
            return thunkAPI.rejectWithValue(message)
             
         }
})


//LogOutWorker Worker user
//logout User
export const logoutWorker = createAsyncThunk('workerUser/logout', async () => {
    workerAuthService.logoutWorkerService();
})









export const workerAuthSlice = createSlice ({
    name: 'workerUser',
    initialState,
    reducers:{

        resetWorkerUser: (state) => initialState,
        
    },
    extraReducers : (builder) => {
        builder 
        .addCase(loginWorker.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(loginWorker.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.workerUser = action.payload
        })
        .addCase(loginWorker.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          
        })

        .addCase(logoutWorker.fulfilled, (state) => {
            state.workerUser = null
        })  
        

    }
})

export const{resetWorkerUser} = workerAuthSlice.actions
export default workerAuthSlice.reducer