
import React, { useEffect, useState } from 'react';

function PrivacyPolicy (){
    const [policy , setPolicy] = useState('')
    
    useEffect(() => {
        fetch('/timeStatPolicy.html')
            .then(response => response.text())
            .then(data => {
                setPolicy(data);
            });
    }, []);

   

  

    return (
        <div className="overflow-auto h-[500px] p-4 w-full md:w-1/2 mx-auto" dangerouslySetInnerHTML={{ __html: policy }} />
    );
}

export default PrivacyPolicy