import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify'

const PasswordStep = ({setPassword, nextStep}) => {
    
    //states to hold the password values.
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    
    //handles the change in the password fields.
    const handlePassword1Change = (e) =>{
        setPassword1(e.target.value);
    }
    const handlePassword2Change = (e) =>{
        setPassword2(e.target.value);
    }   

    //if the passwords match, set the password state in the parent compoent.
    const handleSetPassword = () => {
    
        const passwordRegex = /^(?=.*\d).{8,}$/;
        
        if (!passwordRegex.test(password1)) {
            toast.error("Password must be at least 8 characters long and contain a digit.");
            toast.error("Contraseña debe tener almenos 8 caracteres y un digito.");
        } else if (password1 !== password2) {
            toast.error("Passwords do not match");
        } else {
            setPassword(password1); //calls the setPassword function from the parent component.
            nextStep();
        }
    };
   
    return (
        <div className='flex flex-col bg-slate-300 p-2'>
            
            
            <h1 className='font-normal text-blue-700'>Pick a new Password</h1>
            
            <div className='mb-2'>
                <label
                    className='block text-sm font-medium text-gray-700' 
                    htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password1}
                    onChange={handlePassword1Change}
                    onPaste={event => event.preventDefault()}
                    className='border rounded border-black px-2'
                />

            </div>
           
            <div className='mb-4'>
                <label
                    className='block text-sm font-medium text-gray-700' 
                    htmlFor='confirm'>
                    Confirm:
                </label>
                <input
                    type="password"
                    id="confirm"
                    value={password2}
                    onChange={handlePassword2Change}
                    onPaste={event => event.preventDefault()}
                    className='border rounded border-black px-2'
            />

            </div>

            <div className='mt-2'>
                <button onClick={handleSetPassword} className='bg-indigo-700 text-white p-1 rounded'>
                    Next(Child)
                </button>
            </div>
           
        </div>
    );
};

export default PasswordStep;