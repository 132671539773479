
import React, { useEffect } from 'react';

function BuyButtonComponent() {
  
  // console.log("Testing dotENV", process.env.REACT_APP_TEST);
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;

    script.onload = () => {
      // Stripe script has loaded, you can now use stripe elements
      console.log('Stripe script loaded successfully');
    };

    script.onerror = () => {
      console.error('Error loading Stripe script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    
      <stripe-buy-button
        buy-button-id = {process.env.REACT_APP_STRIPE_BUY_BUTTON_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      />

  );
}

export default BuyButtonComponent;
