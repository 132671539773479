import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import {toast} from 'react-toastify'
import Address from '../components/Address';
import BackButton from '../components/BackButton';
import { purchaseFieldDevice } from '../actions/purchaseFieldDevice';
import axios from 'axios';


const OrderNewDevicesList = () => {
  const { token } = useSelector((state) => state.auth.user);

  const [currentPrice, setCurrentPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);


  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  async function getCurrentPrice ()  {

    try {
      const response = await axios.get('/api/pay/getProductPrice'  , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); //need to send headers and auth token       
      setCurrentPrice(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
   
    getCurrentPrice();
  },);


 
  



 

  // const handleCheckout = () => {
  //   // Show the payment component by setting the showPayment state variable to true
  //   setShowPaymentComponent(true);
  // };

  //checkout using the customer's default payment method
  const handleCheckoutCardOnFile = () => {
   
  }

  
  return (
    <>
  <div className='flex'>
    <div>
      <BackButton url='/homedash' />
    </div>
  </div>

  <div className="w-full bg-black p-2 rounded-sm mt-2">
    <h1 className="text-white mt-3 text-3xl font-bold underline">Order Field Devices</h1>

    <div className='flex justify-between'>

      {/* Product Description column */}
      <div className="w-3/4">
        <img src="/TimeStatDev.svg" alt="Product Image" className="w-full h-48" />

        {/* product Description */}
        <div className=''>
          <div className="text-center">
            <h2 className="text-white text-2xl font-bold">TimeStat Field Device: ${currentPrice}</h2>
          </div>
          
          <div className="max-w-lg">
            <p className="text-slate-400 text-sm text-left ml-2">It is used to track worker's time and attendance.
              Separate subscription of.
              $5 per active device per month is required. You can deactive/activate as needed.</p>
          </div>
        
          
          <h1 className='underline text-left text-white text-lg mt-2 ml-2'>Features:</h1>
          <ul className="text-left mt-2 ml-2 text-white">
           
              <li className="text-sm mt-0 flex items-center">
                <span className="mr-2">
                  <FaCheck  className='text-green-400'/>
                </span>
                Store up to 200 workers
              </li>
              <li className="text-sm mt-0 flex items-center">
                <span className="mr-2">
                <FaCheck  className='text-green-400'/>
                </span>
                Does Not Require WiFi (No WiFi in Construction sites, right?)
              </li>
              <li className="text-sm mt-0 flex items-center">
                <span className="mr-2">
                  <FaCheck  className='text-green-400'/>
                </span>
                Portable and rechargeable.
              </li>
              <li className="text-sm mt-0 flex items-center">
                <span className="mr-2">
                <FaCheck  className='text-green-400'/>
                </span>
                Data immediately available via web and mobile apps.
              </li>
          </ul>
        </div>
      </div>

      {/* Summary column */}
      <div className="w-1/2">
        <Address/>
       
      
        <div className="text-left">
          <h1 className="text-white mt-6 mb-0">Order summary:</h1>
       
              <div className="flex items-center">
                <h1 className="text-white mt-6 mb-0 ">Quantity:</h1>
                <select
                  className="mt-2 p-2 border border-gray-300 rounded-md ml-2 cursor-pointer"
                  defaultValue={quantity}
                  onChange={handleQuantityChange}
                >
                  {[...Array(5)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>

                
              </div>
              <div className='mt-2'>
                 <h1 className='text-white text-xl mb-0'> Total: { currentPrice * quantity }</h1>
                 <p className='text-sm text-slate-50 mt-0 ml-4 underline'>**Shipping included</p>

              </div>

              <div className="text-center">
                <button className='bg-green-400 px-4 py-1 rounded-lg text-xl mt-6 cursor-pointer'>
                  Pay
                </button>
              </div>
             
          

        </div>
      </div>

    </div>
  </div>
</>
   );
 };

 export default OrderNewDevicesList;