import axios from 'axios'

const API_URL_SendCode = 'api/users/forgotPassword'
const API_URL_VerifyCode = 'api/users/forgotPassword/verify'
const API_URL_UPDATEPASSWORD = 'api/users/forgotPassword/verify/success'

const sendCodeService = async (phone) => {
    try {
        const response = await axios.post(API_URL_SendCode, { phone }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            return { message: "Something Went wrong" };
        }
        if (error.response || error.response.status === 401) {
            throw new Error("User does not exist :(");
        }
        throw error;
    }
}


const verifyCodeService = async (data) => {
    try {
       
        const response = await axios.post(API_URL_VerifyCode, { data }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
        
    } catch (error) {
        if (error.response && error.response.status === 400) {
            return { message: "Something Went wrong" };
        }
        if (error.response && error.response.status === 401) {
            throw new Error("Invalid verification Code :(");
        }
        if (error.response && error.response.status === 500) {
            throw new Error("Old Code Submission or Code alreadyverified");
        }
        throw error;
        
    }
}

//dont forget to include backend checks for password length
const submitNewPassword = async(dataNewPassword) =>{
    
    const config = {
        headers:{
            Authorization: `Bearer ${dataNewPassword.token}`,
        },
    }
    
    const response = await axios.post(API_URL_UPDATEPASSWORD,dataNewPassword,config)
   
    return response.data
    
}

const passwordResetService = {
  sendCodeService,
  verifyCodeService,
  submitNewPassword,
}

export default passwordResetService;
