//this is the jobSites page, 
//it fetches a list of jobsites that belong to the user and render each jobsite in its own card component


import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import {toast} from 'react-toastify'
import getJobSites from '../actions/getJobSites'
import deleteJobsite from '../actions/deleteJobsite'
import { toggleJobsiteStatus } from '../actions/toggleJobsiteStatus';
import JobSiteCard from '../components/JobSiteCard'
import { MdOutlineDomainAdd } from "react-icons/md";

const JobSites = () => {

    //get user token from redux store. 
    const {token} = useSelector(state => state.auth.user);
    
    //state of the jobsites, this will be an array of jobsites.
    const [jobSites, setJobSites] = useState([])
    
    //only the user can delete a jobsite, so there is no need to make another request to the database to 
    //get the jobsites again from the database. This would be an expensive operation.
    //instead, we can just remove the jobsite from the state.(if the delete request is successful)
    const handleDeleteJobsite = async (jobSiteId) =>{
        try{
             //delete the jobsitefrom the database, this is an axios delete request.
        const response = await deleteJobsite(token, jobSiteId );
      
        //if jobsite is deleted successfully, remove it from the state.


        if(response.status === 200) {

            console.log("delete jobsite with id: ", jobSiteId)
            const updatedJobSites = jobSites.filter(jobSite => jobSite.id !== jobSiteId);
            setJobSites(updatedJobSites);


        }else{
            console.error('The jobsite wa not deleted  in DB:', response);
        }

        }catch(error){
            console.error('Error deleting job site:', error);
            toast.error('Could not delete the jobsite. Please try again.')

        }
    }

    //will toggle the activation status of a given jobsite
    const handleToggleStatus = async (jobSiteId) =>{
        try{
            //delete the jobsitefrom the database, this is an axios delete request.
         const response = await toggleJobsiteStatus(token, jobSiteId );

         //if the response status is good, then update the state
            
       //if jobsite is deleted successfully, remove it from the state.

        }catch(error){
            console.error('Error deleting job site:', error);
            toast.error('Could not delete the jobsite. Please try again.')

        }
    }



    //fetch job sites when the component mounts
    useEffect( () => {
      
        const fetchJobSites = async () => {
            try {
                const jobsiteData = await getJobSites(token);
       
                setJobSites(jobsiteData);
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchJobSites();
       
    }, [token]);

    
    return (
    <>  
        <div className='flex justify-end '>
            
           {/* add new jobsite button */}
           
            <div>
                <button className='p-1 rounded-full relative group  bg-green-300'> 
                    <Link to='/newJobSite'> <MdOutlineDomainAdd className='text-green-900 text-5xl p-1'/> </Link>
                    <span className=" text-sm absolute top-[55px] p-1 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-slate-600
                     text-white rounded-md pointer-events-none"
                      >
                        New Job Site
                    </span>
                </button>

            </div>
         
            
        </div>
        
       
       
        
        <div className="p-2">
          
        {/* render a card for each jobsite, the key is the jobsiteID */}
            <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3">
                {jobSites.map((jobSite) => (
                <JobSiteCard key={jobSite.id} jobSite={jobSite} 
                onDelete={handleDeleteJobsite}
                onToggleStatus = {handleToggleStatus} />
                ))}
            </div>


        </div>
      


    </>
    )
}

export default JobSites