//Get a workers Transactions 
import axios from "axios"

//These are backend Routes that get proxied to port 5001
const API_GETSIETRANSACTIONS = 'api/dash/siteTransactions/'
const API_GETWORKERTRANSACTIONS = 'api/dash/workerTransactions/'




//get Worker'S TRANSACTIONS by his ID 
const getTransactionsByWorkerId = async(workerID, token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETWORKERTRANSACTIONS + workerID, config)
    return response.data
}  


//javascript doesnt suppoert method overloading, 
//Get transactions by site ID
const getTransactionsBySiteId = async(siteID, token) => {
    
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

    const response = await axios.get(API_GETSIETRANSACTIONS + siteID, config)
    return response.data
}  



//get users on a site on a given date. 
const transactionsBySiteIdFiltered = async( date,  siteID, token) => {
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }


    const response = await axios.post(`/api/dash/siteTransactions/${siteID}/dateFiltered`, date ,config)
    return response.data
} 

//get users on a site on a given date. 
const transactionsByWorkerDateRangeFiltered = async( dateRange,  workerId, token) => {
    const config = {
        headers:{
            Authorization : `Bearer ${token}`,
        },
    }

   
    const response = await axios.post(`/api/dash/workerTransactions/${workerId}/dateFiltered`, dateRange ,config)
    return response.data
} 

const transactionService = {
   getTransactionsByWorkerId,
   getTransactionsBySiteId,
   transactionsBySiteIdFiltered,
   transactionsByWorkerDateRangeFiltered,
}



export default transactionService
