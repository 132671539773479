import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { getSite } from '../features/sites/siteSlice'
import {getTransactionsBySiteId} from '../features/transactions/transactionsSlice';
import {toggleDeviceStatus} from "../features/sites/siteSlice"
function SiteItem({site}){

    let siteID = site.id;
    const dispatch = useDispatch();

    return(
        //change class name in CSS file
        <div className='sitesColumn'>
             <Link to={`/sites/transactionsBySite/${site.id}`} className={`status-${site.status}`} 
           onClick={() => {
            dispatch(getSite(siteID))
            dispatch(getTransactionsBySiteId(siteID))
           }} >
              <p className='rounded-lg '>{site.uniqueID}</p>
           </Link>

            {/* remove button and make it a link, with gear */}
            <button className={`status status-${site.status}`}
                    
            onClick= { () => {
                dispatch(toggleDeviceStatus (siteID))
            }}
            >
                {/* {} for javascript code */}
                {site.status? (
                   <p>Active</p>
                ) : (<p>Inactive</p>)}
            </button>
          
           
           <Link to={`/sites/${site.id}/registry`} className='bg-orange-300 rounded-md hover:bg-orange-400' 
           onClick={() => {
            dispatch(getSite(siteID))
           }}>
        
            Registry
           </Link>
        </div>

    )
}

export default SiteItem


