//updates worker information.
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { updateWorker, reset, terminateWorker } from '../features/workers/workerSlice';
import { toast } from 'react-toastify'


const UpdateWorkerInfo = () => {
  //props passed from previous route on click. 
  const location = useLocation();
  const props = location.state;

  const [privilege, setPrivilege] = useState('Worker');

  // const user = useSelector()
  const dispatch = useDispatch();

  //redux states
  const {message, isSuccess} = useSelector(state => state.workers)
  
 
    //whenever the message changes, show a toast message
    useEffect(() => {
  

      if(message) {
        if(isSuccess === false){
          toast.error(message);
        } else {
          toast.success(message);
        }
        dispatch(reset())
      }
        
    }, [message, isSuccess, dispatch]);


    
  const [formData, setFormData] = useState({
    workerID : props.currentWorkerData.id,
    newRole : '',
    newRate: '',
    newPhone: '',
    privilege: privilege ,
  });



  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;
    if (name === 'newPhone') {
      // Remove all non-digit characters
      formattedValue = formattedValue.replace(/\D/g, '');
  
      // Add dashes in the pattern ###-###-####
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0, 3) + '-' + formattedValue.slice(3);
      }
      if (formattedValue.length > 7) {
        formattedValue = formattedValue.slice(0, 7) + '-' + formattedValue.slice(7, 11);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue
    }));
  };

  const handlePrivilegeChange = (e) => {
    setPrivilege(e.target.value);

    setFormData((prevData) => ({
      ...prevData,
      privilege: e.target.value
    }));
  };


  const handleSubmit = (e) => {
    
    e.preventDefault(); 
    //console.log("Form Data: ", formData);

    //dispath the updateWorker function with formData as parameter. 
     dispatch( updateWorker( formData) )

  };

  const handleRemoveWorker =(e) =>{
    e.preventDefault();

    dispatch(terminateWorker(props.currentWorkerData.id))

  }

  return (
    <>
      <div className="flex justify-center">
      <div className="sm:w-full md:w-1/2 p-2 ">
        <h2 className="sm:text-md  md:text-2xl font-sans mb-2">Update Worker Information</h2>
        
        <form onSubmit={handleSubmit} className=' space-y-4 '>
          <div className="grid grid-cols-2">
            <div className="w-full p-1">

                {['name', 'lastName', 'rate', 'role', 'phone', 'permission'].map((field) => (
                
                <p className="flex justify-start capitalize ">{field}:
                  <span className=" text-gray-600"> {props.currentWorkerData[field]}</span>
                </p>
              ))}
             
            </div>

            <div className="w-full ">
              <h1 className="text-md underline mb-2 ">New Changes</h1>
              
             
              <input
                name='newRate'
                type="number"
                step = "0.01"
                placeholder="Hourly Rate"
                value={formData.newRate}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-400 w-full rounded-md"
                pattern="^(?!0$)\d{1,3}(?:\.\d{2})?$"
                required
                min={7.25}
              />
              <input
                name='newRole'
                type="text"
                placeholder="New Role"
                value={formData.newRole}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-400 w-full  rounded-md"
                required
              />
              <input
                name="newPhone"
                type="text"
                placeholder="New Phone"
                value={formData.newPhone}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-400 w-full rounded-md"
                required
              />

              {/* two radial buttons  */}
              
                <select name="privilege"
                 onChange={handlePrivilegeChange}
                 className='w-full p-2 border border-gray-400 rounded-md'>
                    <option value="Worker">Worker</option>
                    <option value="Supervisor">Supervisor</option>
                </select>
              
            </div>
            
          </div>

          <div className='flex justify-evenly'> 
              
            <BackButton url={"/workers"} />
            
            <button type="button" className='bg-white  text-red-600 border-2 border-red-600 px-2 py-2 rounded-lg hover:bg-red-500 hover:text-white '
              onClick={handleRemoveWorker}>
              Delete
            </button>
            
            <button type="submit" className="py-2 px-2 bg-green-200 rounded-lg hover:bg-green-500">
               Update 
            </button>

          </div>
          
          </form>
          </div>
        </div>
    </>
    
  )}

export default UpdateWorkerInfo


