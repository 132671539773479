import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import transactionsService from './transactionsService' 

const initialState = {
    transactions: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


//get a worker's transactions 
export const getTransactions = createAsyncThunk('transactions/getByWorkerID', 
    async( workerId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await transactionsService.getTransactionsByWorkerId(workerId, token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

// Get transactions taken place on a site. On current Date. 
export const getTransactionsBySiteId = createAsyncThunk('transactions/getBySiteID', 
    async( siteId, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            const token = thunkAPI.getState().auth.user.token
            return await transactionsService.getTransactionsBySiteId(siteId, token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

export const transactionsOnSiteDateFiltered = createAsyncThunk('transactions/bySiteIdDateFiltered', 
    async( date, thunkAPI) =>{
        
        try {

            
            const token = thunkAPI.getState().auth.user.token
            const siteId = thunkAPI.getState().sites.siteInfo.id //available in the redux state
            // date and siteId are params, token to verify user remains logged in. 
            return await transactionsService.transactionsBySiteIdFiltered( date , siteId,  token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})

export const transactionsByWorkerDateRange = createAsyncThunk('transactions/byWorkerdDateRange', 
    async( dateRange, thunkAPI) =>{
        
        try {

            
            const token = thunkAPI.getState().auth.user.token
            const workerId = thunkAPI.getState().workers.workerInfo.id //now this is in the redux state

            return await transactionsService.transactionsByWorkerDateRangeFiltered( dateRange , workerId,  token)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        
        return thunkAPI.rejectWithValue(message)
        }
})



export const transactionsSlice = createSlice({
    name:'getTransactions',
    initialState,
    reducers:{
        reset: (state) => initialState,
    },
    extraReducers: (builder) =>{
        builder 
            .addCase(getTransactions.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.transactions = action.payload
            })
            .addCase(getTransactions.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getTransactionsBySiteId.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getTransactionsBySiteId.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.transactions = action.payload
            })
            .addCase(getTransactionsBySiteId.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(transactionsOnSiteDateFiltered.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(transactionsOnSiteDateFiltered.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.transactions = action.payload
            })
            .addCase(transactionsOnSiteDateFiltered.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(transactionsByWorkerDateRange.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(transactionsByWorkerDateRange.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.transactions = action.payload
            })
            .addCase(transactionsByWorkerDateRange.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
           
           
    },
})

export const{reset} = transactionsSlice.actions
export default transactionsSlice.reducer