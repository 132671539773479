//will upload an image directly to s3 bucket using the preSigned URL
//if image is uploaded successfully, return the image URL from s3, to be then stored in mySQL database.
//if image is not uploaded successfully, return an error message


import axios from "axios";
import { toast } from "react-toastify";
const uploadImageToS3 = async (file, signedUrl) => {
    // console.log("uploading image to s3");

    try {
        //upload the file to s3
     
        const response  = await axios.put(signedUrl,file,{
            
            headers:{
                'Content-Type': file.type,
            }
        });

        if(response.status === 200){
           
            toast.success('File uploaded successfully'); 
            return ( signedUrl.split("?")[0] );

        }else{
            return null;
        }
        
    } catch (error) {
        console.error("Error uoloading the file: " , error);
        toast.error('Error uploading the file');
        throw new Error('Error uploading the file')
    }

}

export default uploadImageToS3;