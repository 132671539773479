import React from 'react';

const Faq = () => {
  return (
    <>
      <section className="text-gray-700 p-2 mt-4">
        <div className="container px-2">
          <div className="text-center mb-2">
            <h1 className="text-2xl font-mono text-center text-red-600">
              Frequently Asked Questions
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              If your question is not here:{' '}
              <a
                className="text-bold text-red-500"
                href={`mailto:info@megasoftwaresolutions.com?body=From web App`}
              >
                Click here to send us an E-mail.
              </a>
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4" aria-expanded="false" aria-controls="question1-content">
                  How many workers can I have?
                </summary>
                <span id="question1-content">
                  Each device can store up to 200 Workers, but you can have many more in your account.
                </span>
              </details>

              <details className="mb-4">
                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                What if I finished a job?
                </summary>

                <span>
                You may use existing device at another location. De-activate the device until you need it again, or if it's in good condition send it back to us and we will credit your account. 
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                How many devices do I need?
                </summary>

                <span>
                One device per site, each device is assigned a location.
                </span>
              </details>
            </div>
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                Does it work outdoors?
                </summary>

                <span className="px-4 py-2">
                Yes, we even tested it on top of Mt.Everest, lieterally. 
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                I have a trucking company, can it move around ?
                </summary>

                <span className="px-4 py-2">
                Yes, timeStat can be moved all around the city, state, or country (Continental United States)
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                 Are the fingerprints secured?
                </summary>

                <span className="px-4 py-2">
                Yes, they remain encrypted on your device and we never see them.
                </span>
              </details>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;

