//Display cards from the Database that belong to a customer. 

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';

const CardsList = () => {
  const { token } = useSelector((state) => state.auth.user);
  const [cards, setCards] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});

  useEffect(() => {
    //get default payment method from stripe asynchronously
    const getDefaultPaymentMethod = async () => {
      try {
        const getDefaultPM = await axios.get('/api/pay/getDefeaultPaymentMethod', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        console.log(getDefaultPM.data);
        setDefaultPaymentMethod(getDefaultPM.data);
      } catch (error) {
        console.error('Error getting default payment method:', error);
        // You can also set some state here to indicate that an error occurred
      }
    };

    const getAllCardsBelongingToCustomer = async () => {
      try {
        const response = await axios.get('/api/pay/customerCards', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        console.log(response.data); 
        if (Array.isArray(response.data)) {
          setCards(response.data);
          
        }
      } catch (error) {
        console.log(error);
      }
    };


    
    
    
    
    //when component mounts, get the default payment method
     getDefaultPaymentMethod();
    //get all the cards belonging to the customer
     getAllCardsBelongingToCustomer()

  }, [token]);

  //detach the card from customer and delete from db.
  const handleRemoveCard = async (pmID) => {
    try {
      await axios.post('/api/pay/detachPaymentMethod', { paymentMethodID: pmID }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Remove the deleted card from the list of cards displayed in the component
      // This will filter the cards array to remove the card with the id equal to cardId, and then update the cards state with the filtered array.
      setCards(cards.filter(card => card.id !== pmID));
    } catch (error) {
      console.error('Error removing card:', error);
      // You can also set some state here to indicate that an error occurred
    }
  };

  //set the card clicked on as the default card.
  const handleSetDefault = async (cardId) => {
    try {
      //send the user token and the payment method id to the backend to set the default payment method.
      const res = await axios.put('/api/pay/setDefaultPm', { cardId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the defaultPaymentMethod state with the updated default card
      setDefaultPaymentMethod(res.data);
    } catch (error) {
      //use alert to display error message
      console.log(error);
    }
  };

  return (
    <>
      <div className=''>
        <h1 className="text-lg text-left font-sans font-normal">Payment Methods</h1>

      </div>
       
      <div className="overflow-x-auto">
       
        <table className="w-full min-w-max">
          <thead>
            <tr className=' font-sans'>
              <th className="px-2  text-left text-xs  uppercase tracking-wider md:px-6">Name on Card</th>
              <th className="px-2  text-left text-xs  uppercase tracking-wider md:px-6">Type</th>
              <th className="px-2  text-left text-xs  uppercase tracking-wider md:px-6">Last 4</th>
              <th className="px-2  text-left text-xs  uppercase tracking-wider md:px-6">Expiration Date</th>
            </tr>
          </thead>
          <tbody className='text-left text-gray-500'>
            {cards?.map((card) => (
              <tr key={card.paymentMethodID}>
                <td className="px-3 py-2 whitespace-nowrap">{card?.name ?? 'Unknown'}</td>
                <td className="px-3 py-2 whitespace-nowrap">{card.brand}</td>
                <td className="px-3 py-2 whitespace-nowrap">{card?.last4 ?? 'Unknown'}</td>
                <td className="px-3 py-2 whitespace-nowrap">{card?.exp_Month && card?.exp_Year ? `${card.exp_Month}/${card.exp_Year}` : 'Unknown'}</td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {/* On click, detach the pm from customer and then remove from the database. */}
                  <button type='button'
                    onClick={() => handleRemoveCard(card.paymentMethodID)}
                    className='rounded p-1 mr-2 text-red-400 hover:text-red-500'
                    disabled={card.paymentMethodID === defaultPaymentMethod} >
                      
                      {card.paymentMethodID !== defaultPaymentMethod && <FaTrash />}
                   
                   </button>
                  {/* conditionally give it a green 200 bg if pmID is eqaul to defaultpm state */}
                  <button
                    type="button"
                    onClick={() => handleSetDefault(card.paymentMethodID)}
                    className={`rounded ${
                      card.paymentMethodID === defaultPaymentMethod ? "bg-green-200 p-2" : "hover:bg-green-300"
                    }`}
                 
                    disabled={card.paymentMethodID === defaultPaymentMethod}
                  >
                    {card.paymentMethodID === defaultPaymentMethod ? "default" : "set as default"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CardsList;
