//User Registration Page
import {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
//select anything from global state
import {useSelector} from 'react-redux'
//dispatch our action
import {useDispatch} from 'react-redux'
import {register} from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Register (){
    const [agreement, setAgreement] = useState(false)
    const [formData, setFormData] = useState({
        companyName:'',
        name:'',
        email:'',
        phone:'',
        password:'',
        password2:''
    }) 

    const {companyName, name, email, phone, password, password2} = formData

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    //get current values from global state redux
    const {user, isError, isSuccess, isLoading, message} = useSelector(state => state.auth)

     useEffect(()=>{
        if(isError){
            
            toast.error(message)
        }
        if(isSuccess || user){
             
            navigate('/verify')
            

        }

        //do no reset, instead send verification code
        // dispatch(reset())

    },[isError,isSuccess,user,message,navigate,dispatch])



    const onChange = (e) => {
        if (e.target.name === "phone") {
          // remove all non-numeric characters from the input value
          const numericValue = e.target.value.replace(/\D/g, '');
          // limit the input to accept only 10 digits
          const limitedValue = numericValue.substring(0, 10);
          // add "-" after 3rd and 6th digits
          let formattedValue = '';
          for (let i = 0; i < limitedValue.length; i++) {
            if (i === 3 || i === 6) {
              formattedValue += '-';
            }
            formattedValue += limitedValue.charAt(i);
          }
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: formattedValue,
          }));
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
      };
      
    

    const onSubmit = (e) =>{
        e.preventDefault()
        if (!agreement) {
            toast.warning('You must agree to the privacy policy before submitting.');
            return;
        }

        if(password !== password2){
            toast.error("passowords do not match")
            return;
        }

        const userData = {
                name,
                email,
                phone,
                password,
                companyName,
        }
        dispatch(register(userData));    
    }

    if(isLoading) {
        return<Spinner/>
    }

    return(
    <>  
    <div className='bg-slate-300 p-2'>


        <section>
            <h1 className="text-3xl font-normal mb-4 p-2">Business Registration</h1>
        </section>

        <section className='form p-4 rounded-[24px]'>
            <form onSubmit={onSubmit}>

                <div className='form-group'>
                    <label htmlFor="companyName">Business Name </label>
                    <input className='form-control'
                    type='text' id='companyName' name='companyName' value={companyName} onChange={onChange} placeholder='DryWall Contracting' required>
                    </input>
                </div>

                <div className='form-group'>
                    <label htmlFor="name">Contact Person </label>
                    <input className='form-control'
                    type='text' id='name' name='name' value={name} onChange={onChange} placeholder='Enter Your name' required>
                    </input>

                </div>

                <div className='form-group'>
                    <label htmlFor="email">Business Email</label>
                    <input className='form-control'
                    type='email' id='email' name='email' value={email} onChange={onChange} placeholder='person@Business.com' required>
                    </input>

                </div>
                <div className='form-group'>
                    <label htmlFor="phone">Business Phone Number</label>
                    <p className='font-mono text-sm'>Must be able to receive text messages</p>
                    <input className='form-control'
                    type='tel' id='phone' name='phone' value={phone} onChange={onChange} placeholder='123-123-1234' required>
                    </input>

                </div>
                <div className='form-group'>
                    <label htmlFor="password">Password</label>
                    <input className='form-control'
                        type='password' 
                        id='password' 
                        name='password' 
                        value={password} 
                        onChange={onChange} 
                        placeholder='$ecurePa$$w0rd123' required>
                    </input>
                </div>

                <div className='form-group'>
                    <label htmlFor="password2">Confirm Password</label>
                    <input className='form-control'
                    type='password' 
                    id='password2' 
                    name='password2' 
                    value={password2} 
                    onChange={onChange} 
                    placeholder='Confirm Password' required>
                    </input>

                </div>

                <div className='mb-4'>
                    <label className='inline-flex items-center text-sm'>
                        <input type='checkbox' 
                            className='form-checkbox w-6 h-6'
                            onChange={(e) => setAgreement(e.target.checked)}
                            />
                        <span className='ml-2 font-thin'>I agree to the <a href='/privacyPolicy' className='text-blue-600'>Privacy Policy</a> and to receive SMS verification code</span>
                    </label>
                </div>
                
                
                <button className='bg-blue-600 text-white rounded text-lg p-2 '> Submit</button>
            

            </form>
        </section>
    </div>
           
    </>
    
    )
  }


  export default Register