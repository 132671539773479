


const features = [
    { name: 'Lateness', description: 'Workers arriving just minutes late, or leaving early... it adds up. You know it happends, be aware of who, when and where.' },
    { name: 'Verification', description: 'Bob claims he is owed hours from last week Tuesday, he forgot to sign, but swears Mike saw him. Sounds familiar? ' },
    { name: 'Fraud', description: 'Such as a worker clocking in for someone else...  TimeStat relies on a fingerprint sensor,  ensuring fraud does not occur.' },
    { name: 'IRS', description: "Have an accurate record of your workers' hours, during tax season you can easily generate  W-2 or 1099 forms at the end of year." },
    { name: 'Less Stress', description: 'An incorrect paycheck is stressful for both employee and employer. If something must be verified, timeStat makes it easy, no more doubts.' },
    { name: 'More Features Coming soon', description: 'We are just getting started, more features will be added! Sign up today and lock-in this price.' },
  ]
  
  export default function TypicalProblems() {
    return (
      <div className="bg-white">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-mono tracking-tight text-red-600 sm:text-4xl">Tackling the main problems</h2>
              <p className="mt-4 text-gray-500">
                 Jobsite connectivity can be difficult to establish. Our app makes TimeStat easy to set up and manage,  
                 <span className="border-b-4 border-red-600"> without needing WiFi.</span> 
              </p>
  
            <dl className="mt-16 grid grid-cols-1  gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt>{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
          
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/0sz3XtNqDwo"
              title="YouTube Spanish"
             
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/-HBbtWBeE7Q"
              title="YouTube English"
           
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    )
  }
