import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import siteReducer from '../features/sites/siteSlice'
import workerReducer from '../features/workers/workerSlice'
import transactionsReducer from '../features/transactions/transactionsSlice'
import registryReducer from '../features/registry/registrySlice'
import passwordResetReducer from  '../features/passwordReset/passwordResetSlice'
import subscriptionReducer from '../features/subscription/subscriptionSlice'
import workerAuthReducer from '../features/workerAuth/workerAuthSlice'
//or add it in component only. 
export const store = configureStore({
  reducer: {
    auth: authReducer,
    passwordReset: passwordResetReducer,
    sites: siteReducer,
    workers: workerReducer,
    transactions: transactionsReducer,
    registry:registryReducer,
    subscription: subscriptionReducer,
    workerAuth: workerAuthReducer,

  },
});
