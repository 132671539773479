//this function toggles the status of a jobsite from active to inactive or vice versa

import axios from "axios";


const toggleJobsiteStatus_API = "/api/dash/toggleJobsiteStatus";

//names export function requires the jobsitedID and the user token to be passed in
export const toggleJobsiteStatus = async (jobsiteID, token) => {
    console.log("toggleJobsiteStatus function called")

    //the data to be sent to the backend in the body 
    const putData = {
        jobsiteID : jobsiteID
    }

    try {
        

        const response = await axios.put(toggleJobsiteStatus_API, putData,{
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        
       //returns just the current status, and the id a given Jobsite
        return response.data;
        

    } catch (error) {
        console.log("Something went wrong with updating the database with the image URL.")
        throw error;
    }
}

