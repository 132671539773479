//performs http delete request to delete a nobsite 

import axios from "axios";


//backend API endpoint
const deleteJobSite_API = "/api/dash/delete/jobsite"


const deleteJobsite = async (token, jobsiteID) => {
    console.log("jobsiteID", jobsiteID);
    
    try {
        const response = await axios.delete(deleteJobSite_API, {
            headers: { 
                'Authorization': `Bearer ${token}`
            },
            data: {
                jobsiteID: jobsiteID
            }
        });
        
        return response;
    } catch (error) {
        console.log("Error deleting the jobsite" + error);
        throw error;
    }
}


export default deleteJobsite;