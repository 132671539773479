import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

import authService from './authService'

//get user from local storage
const userW = JSON.parse(localStorage.getItem('user'))

const initialState = {
    //user: userW? userW : 'null',       // if there is no user in local storage, set to null
    user: userW,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    verified:'',

}


//a function to register a user
export const register = createAsyncThunk('auth/register', 
    async(user, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            return await authService.register(user)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
                return thunkAPI.rejectWithValue(message)
        }
})


//login User
export const login = createAsyncThunk('auth/login', 
    async(user, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
         try {
             return await authService.login(user)
         } catch (error) {
            const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
                return thunkAPI.rejectWithValue(message)
             
         }
})

//logout User
export const logout = createAsyncThunk('auth/logout', async () => {
    await authService.logout()
})


//Twilio API verification 
export const verify = createAsyncThunk('auth/verify',
    async(formData, thunkAPI) =>{
        try {
            //Q?  Do i need the token here ??

            //token is useful for auth,   if i use token i can grab phone from backend dabase.... but it is slower when i could just obtain it from the from. 
            
            //de-structure formData
            const phone = thunkAPI.getState().auth.user.phone
             const code = formData.code
            //  console.log(code)
            //  console.log(phone)
          
            //  console.log(token)
          
            return await authService.verifyTwilio(code,phone)
        } catch (error) {
           const message =
           (error.response && error.response.data && error.response.data.message) ||
           error.message ||
           error.toString()
               return thunkAPI.rejectWithValue(message)
        }

})





export const authSlice  = createSlice ({
    name: 'auth',
    initialState,
    reducers:{

        //no asyncThunk required so, reset added here.
        reset: (state =>{
            state.isLoading = false
            state.isSuccess = false
            state.isError =false
            state.message = ''
            state.verified= ''
            // state.dbVerified= ''
        }),
    },
    extraReducers: (builder) =>{
        builder
            .addCase(register.pending, (state) =>{
                state.isLoading =true
         })
            .addCase(register.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) =>{
                state.isLoading = true
            })

            .addCase(login.rejected, (state,action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })

            .addCase(login.fulfilled, (state,action)=>{
                state.isLoading =false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(verify.pending, (state)=>{
                state.isLoading = true            
            })
            .addCase(verify.fulfilled, (state,action)=>{
                state.isLoading =false
                state.isSuccess = true
                state.verified = action.payload
            })
            .addCase(verify.rejected, (state,action)=>{
                state.isLoading = false
                state.isError = true
                state.verified = action.payload
            })
    }
})


export const {reset} = authSlice.actions
export default authSlice.reducer
