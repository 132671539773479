

//will take a s3 url as prop, and a file input with a submit button. 
import React, { useState} from "react";
import uploadImageToS3 from "../actions/uploadImageToS3";
import { toast } from "react-toastify";
import {updateImageURL} from '../actions/updateDB'
import {useSelector } from 'react-redux'
const NewSiteImageUploadForm = ({signedURL, jobsiteID}) => {
        //state of the image to be uploaded
        const [image, setImage] = useState(null);
        
        //token taken from redux state
        const {token} = useSelector((state) => state.auth.user ) 
    
        //submit the image to s3 bucket using the signed URL
        const uploadImage = async (e) => {
            e.preventDefault();

            if(!image){
                console.log("No image selected");
                return ;
            }else{

                try{
                    //response contains the s3 object URL of the uploaded image.
                    const imageObjectUrl = await uploadImageToS3(image, signedURL);
                    
                    // console.log("Image uploaded to S3 bucket at: " + imageObjectUrl);

                    //update the database with the image URL 
                    if(imageObjectUrl){
                        try {
                            const updateDatabase = await updateImageURL(imageObjectUrl, jobsiteID, token);
                            toast.success('Image uploaded successfully');
                        } catch (error) {
                            console.log("Error updating the database: " + error);
                        }
                       


                    }
                }catch(error){
                    console.log('Error in uploadImage: ' + error);
                }

            }
    
        }



        const handleImageChange = (e) =>{

                setImage(e.target.files[0]);

            
        }

        const handleSkip = () =>{

            console.log("Skip button pressed")
            //navigate to the sites page.
        }
    
    
    
    return (
        <>

        <h1>Upload a .png file</h1>
        
        <form onSubmit={uploadImage}>

            <label htmlFor="uploadImage"/>
            <input type="file" name="uploadImage" id="uploadImage" accept="image/*" 
                    onChange={handleImageChange}
            />

            <div className="flex-row mt-5 space-x-4">
                <button type="button" className="bg-yellow-400 p-2 text-black rounded" onClick={handleSkip} >Skip</button>
                <button className="bg-black text-white p-2 rounded" type="submit" > Upload Image </button>
            </div>
          

        </form>

        </>

    )



 }





export default NewSiteImageUploadForm;