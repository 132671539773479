import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getSites, reset} from '../features/sites/siteSlice'
import {reset as resetRegistry} from '../features/registry/registrySlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import SiteItem from '../components/SiteItem'
import InfoModal from '../components/InfoModal'
import { Link } from 'react-router-dom'

import { FaPlus,FaCartPlus } from 'react-icons/fa'
function  Sites(){

    const {sites, isLoading, isSuccess, message}= useSelector((state)=> state.sites) 
   
    const dispatch = useDispatch()

    useEffect(() =>{
        return () =>{
            if(isSuccess){
                dispatch(reset())
                //reset the registry when we go back to this route. 
                dispatch(resetRegistry())
            }
        }
    },[dispatch,isSuccess])

    //call it again for a refresh whenever message changes in sites 
    useEffect(()=>{
        dispatch(getSites())
    },[dispatch, message])
    

    if(isLoading){
        return<Spinner />
    }
    return(
       <>
       
       <div className='container flex justify-between'>
                
                <BackButton url='/homedash' />

                    

                <div className="group inline-block relative">
                    <Link to="/new-site" reloadDocument className="bg-green-300 inline-flex items-center px-4 py-2 font-medium rounded-md">
                            <FaPlus className='text-xl mr-2'/>
                            Add New Field Device
                    </Link>
                    <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 w-64 absolute top-0 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 transition-all duration-200">
                            Add a new field device to your account.
                    </span>
                </div>

                

                <div className="group inline-block relative">
                    <Link to="/orderDevice" reloadDocument className="bg-green-300 inline-flex items-center px-4 py-2 font-medium rounded-md">
                            <FaCartPlus className='text-xl mr-2'/>
                            Purchase Field Device
                    </Link>
                    <span className="opacity-0 bg-indigo-700 text-white text-md rounded-md py-1 px-2 w-64 absolute top-0 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 group-hover:translate-y-20 transition-all duration-200">
                            Field Devices are sold separately. Click here to purchase a new device.
                    </span>
                </div>



        </div>

                 
        
    
   
       
       <div className='container font-mono  '>
       
            <div className="flex flex-row mt-4">
                <div className="w-1/3 p-4 cursor-help"><InfoModal columnTitle="Device ID"  
                    description="Clicking a deviceID below will show you the daily attendace, you may choose different
                    dates."/> </div>
                <div className="w-1/3 p-4 cursor-help"><InfoModal columnTitle="Status" 
                    description="When a device is active you will be billed monthly for it. If a job is put on hold,
                    you may de-activate it. Note: To use this timeStat device at another location, you should create a new jobsite."/></div>
                <div className="w-1/3 p-4 cursor-help"><InfoModal columnTitle="Enrollment"     
                    description="To enroll a worker you must first register the worker to a fiel device. Click 
                    here to assign a worker, or add the unique code to a jobsite."/></div>
            </div>

       </div>


     
       <div className="tickets">
            {sites.map((site)=>(
                <SiteItem key={site.id} site={site}/>
            ))}
        </div>

       

       </>
    )
}

export default Sites