import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import passwordResetService from './passwordResetService' 

const initialState = {
    // verification is either pending or approved. 
    verification : {status: "",
                    token: ""},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',

}

//Send new code

export const sendCode = createAsyncThunk('code/send', 
    async(phone, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        try {
            
            return await passwordResetService.sendCodeService(phone)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
                return thunkAPI.rejectWithValue(message)
        }
})


//verify code

export const verifyCode = createAsyncThunk('code/verify', 
    async(data, thunkAPI) =>{
        //thunkAPI to connect to backEnd 
        
        try {
           
            return await passwordResetService.verifyCodeService(data)
        } catch (error) {
            const message = 
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
                return thunkAPI.rejectWithValue(message)
        }
})

//updatePassword
export const changePassword = createAsyncThunk('password/reset' , async(dataNewPassword,thunkAPI) =>{
    try {
        
       
        return await passwordResetService.submitNewPassword(dataNewPassword)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
            return thunkAPI.rejectWithValue(message)
    }
})

export const passwordResetSlice = createSlice ({
    name: 'passwordReset',
    initialState,
    reducers:{

        reset: (state) => initialState,
        
    },

    extraReducers: (builder) =>{
        builder
            .addCase(sendCode.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(sendCode.fulfilled, (state,action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.verification = action.payload
            } )
            .addCase(sendCode.rejected, (state,action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(verifyCode.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(verifyCode.fulfilled , (state,action)=>{
                state.isLoading = false
                state.verification = action.payload
                state.isSuccess = true
            })
            .addCase(verifyCode.rejected, (state,action) =>{
                state.isLoading = false
                state.message = action.payload
                state.isError = true
            })
            .addCase(changePassword.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(changePassword.fulfilled , (state,action)=>{
                state.isLoading = false
                state.message = action.payload
                state.isSuccess = true
            })
            .addCase(changePassword.rejected, (state,action) =>{
                state.isLoading = false
                state.message = action.payload
                state.isError = true
                
            })
    }
   
})

export const{reset} = passwordResetSlice.actions
export default passwordResetSlice.reducer
