import React, { useState } from 'react';
import NewSiteForm from '../components/NewSiteForm';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import BackButton from '../components/BackButton';
const CreateJobSite = () => {
    //will store location info from GoogleMapsComponent to be passed to NewSiteForm
    const [location, setLocation] = useState({});

      return (
        <>
          <div className='flex mb-1'>
            <div className='hidden sm:block'>
              <BackButton url={'/jobsites'}  />        

            </div>
           
          </div>
          
          <div className="bg-gray-700">
            

            <div className='flex flex-col sm:p-0 md:p-2'>
                {/* Pass a callback function to GoogleMapsComponent that will be used to update the state in NewSiteForm. */}
                <div className='sm:w-full  '>
                    <GoogleMapsComponent onLocationChange={setLocation}/>
                </div>
              
              
              
              {/* In NewSiteForm, accept the latitude and longitude as props, and use them to initialize the state of the latitude and longitude inputs. */}
                <div className='container mb-4'>
                    <NewSiteForm initialLatitude={location.lat} initialLongitude={location.lng} initialAddress={location.addr} />
                </div>

            </div>
          </div>
          
        </>
       
      );
};

export default CreateJobSite;