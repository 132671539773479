import {verify} from '../features/auth/authSlice'
import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'
//make this function take in form data from enroll page
function VerifyUser (){
   //grab the phone number from redux store 

  
    const [formData ,setFormData] = useState(
    //json object
    {
    code: '',
    })

   //deStructure formData
   const{code} = formData

   

   const onChange = (e) =>{
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name] : e.target.value, 
    }) )
   }

   const dispatch = useDispatch()
   const navigate = useNavigate()
   
   const {user, isError, isSuccess, message, verified} = useSelector(state => state.auth)

   useEffect(()=>{
    if(isError){
        
        toast.error(verified)
        toast.error(message)
    }

    if(verified === "approved"){
        toast.success(" You are verified, welcome!", {
            position: toast.POSITION.TOP_CENTER
          });
        navigate('/homedash')
    }
    console.log(verified)


},[isError,isSuccess,user, message, verified, navigate,dispatch])




const onSubmit = (e) =>{
    e.preventDefault()

    if(code === ""){
        toast.error("Enter the code recevied ")
    }else{

        dispatch(verify({code}))
    }
}
    
    return(
    <>
        <section className='heading'>
            <h1>
                Enter the verification code 
            </h1>
            
        </section>

        <section className='form'>
            
             <form onSubmit={onSubmit}>
                
                    <div className='form-group'>
                        <input type='text' className='form-control' id='code'  name='code' value={code} onChange={onChange} placeholder="Enter the code" required />
                    </div>

                    <div className='form-group'>
                        <button className='btn btn-block'> Submit</button>
                    </div>
                    
            </form>
                  
        </section>
              
    </>
    
    )
  }


  export default VerifyUser