//instead of a modal that the user will have to close, 
//we can use a tooltip to show the user a description of the column
import React, { useState } from "react";
import { FaRegWindowClose} from 'react-icons/fa';


// the prop is a desciption of the column
const InfoModal = (prop) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        className="cursor-help bg-slate-400 font-semibold px-2 py-1 rounded-md "
        type="button"
        onMouseOver={() => setShowModal(true)}
      
      >
      
      {prop.columnTitle}
      </button>

      {showModal ? (
        <>
          <div className="flex justify-center items-center fixed inset-0 backdrop-blur-sm   ">
            <div className="relative  mx-auto max-w-2xl">
              <div className="rounded-lg shadow-md relative flex flex-col w-full bg-slate-50 outline-none focus:outline-none">
                <div className="flex items-start justify-between p-2 border-b  ">
                  <h3 className="text-lg font-semibold">About {prop.columnTitle}</h3>
                  <button
                    className="bg-transparent border-0"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-red-300 hover:text-red-500 text-3xl">
                      <FaRegWindowClose/>
                    </span>
                  </button>
                </div>
                <div className="relative p-2">
                 
                  <p> {prop.description}</p>
                </div>
            
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default InfoModal;